import { Injectable, EventEmitter } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed }from '@capacitor/push-notifications';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IPushNotification, IPushNotificationResponse } from '../models/push-notification.model';
import { User } from '../models/user.model';
import { CacheInterceptor } from './cache.service';
import { FirebaseMessaging, PermissionStatus } from '@capacitor-firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private readonly baseUrl = environment.API_URL;

  data: IPushNotification = {
    deviceToken: '',
    platform: '',
    bundleId: 'com.qas.liaison',
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private cacheInterceptor: CacheInterceptor
  ) {
    this.data.platform = null;
  }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.data.platform = Capacitor.getPlatform();
      this.registerPush();
    }
  }

  private async registerPush() {

    var status = await this.checkPermissions();

    if (status !== 'granted') {
        status = await this.requestPermissions();
    }

    // PushNotifications.requestPermissions().then((permission) => {
    //   if (permission.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // No permission for push granted
    //   }
    // });

    // PushNotifications.addListener('registration', (token: Token) => {
    //     this.data.deviceToken = token.value;
    //   }
    // );

    // PushNotifications.addListener('registrationError', (error: any) => {
      
    // });

    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   async (notification: PushNotificationSchema) => {

    //   }
    // );

    // PushNotifications.addListener(
    //   'pushNotificationActionPerformed',
    //   async (notification: ActionPerformed) => {
        
    //   }
    // );

  }

  async enablePushNotifications(userId: number): Promise<void> {

    const token = await this.getToken();

    const body: any = {
        id: userId,
        deviceToken: token
    };

    const result: User = await this.cacheInterceptor.processRequest<User>(`${this.baseUrl}/login/push-notification`, 'POST', false, body);
  }

  async checkPermissions() {
    const result = await FirebaseMessaging.checkPermissions();
    return result.receive;
  };
  
  async requestPermissions() {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
  };
  
  async getToken() {
    const result = await FirebaseMessaging.getToken();
    return result.token;
  };

}
