<ion-grid name="add-new-customer-popup">
    <ion-row class="header-row">
        <ion-col>
            Add New Customer
        </ion-col>
        <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
    </ion-row>
    <form [formGroup]="newCustomerForm" (ngSubmit)="submitNewCustomer()">
        <div class="form-container">
            <ion-row class="label-row">
                <ion-label>
                    Name *
                </ion-label>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input name="customer-name" class="quickbooks-input" formControlName="name" placeholder="Enter customer name"></ion-input>
                </ion-col>
            </ion-row>

            <ion-row class="label-row web-address" >
                <ion-col class="ion-no-padding">
                    <ion-label>
                        Country 
                    </ion-label>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-label>
                        Address *
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="address-row web-address" >
                <ion-col>
                    <select name="customer-country" formControlName="country" [ngClass]="{'text-color-grey': !newCustomerForm.value.country}">
                        <option [value]="null" disabled selected>Enter Country</option>
                        <option [ngValue]="'CA'">
                            Canada
                        </option>
                        <option [ngValue]="'KO'">
                            Korea
                        </option>
                        <option [ngValue]="'MX'">
                            Mexico
                        </option>
                        <option [ngValue]="'US'">
                            United States
                        </option>
                    </select>
                </ion-col>
                <ion-col>
                    <ion-input name="customer-address" class="quickbooks-input" formControlName="address" placeholder="Enter address"></ion-input>
                </ion-col>
            </ion-row>

            <ion-row class="label-row web-address">
                <ion-col class="ion-no-padding">
                    <ion-label>
                        {{(newCustomerForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                    </ion-label>
                </ion-col>
                <ion-col class="small-input-col ion-no-padding">
                    <ion-label>
                        City *
                    </ion-label>
                </ion-col>
                <ion-col class="small-input-col ion-no-padding">
                    <ion-label>
                        {{(newCustomerForm.value.country === 'CA') ? 'Province/Territory *' : 'State'}}{{(newCustomerForm.value.country === 'US' ? ' *' : '')}}
                    </ion-label>
                </ion-col>
                <ion-col class="small-input-col ion-no-padding">
                    <ion-label>
                        Postal Code *
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="address-row web-address">
                <ion-col>
                    <ion-input name="customer-address2" class="quickbooks-input" formControlName="address2" placeholder="Enter address"></ion-input>
                </ion-col>
                <ion-col class="small-input-col">
                    <ion-input name="customer-city" class="quickbooks-input" formControlName="city" placeholder="Enter city"></ion-input>
                </ion-col>
                <ion-col class="small-input-col">
                    <ion-input name="customer-state" class="quickbooks-input" formControlName="state" placeholder="Enter state"></ion-input>
                </ion-col>
                <ion-col class="small-input-col">
                    <ion-input name="customer-postal" class="quickbooks-input" formControlName="postal" placeholder="Enter postal"></ion-input>
                </ion-col>
            </ion-row>

            <!-- Switches address row to vertical when screen size is small -->

            <div class="mobile-address">
                <ion-row class="double-row">
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                Country *
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <select formControlName="country" [ngClass]="{'text-color-grey': !newCustomerForm.value.country}">
                                    <option [value]="null" disabled selected>Enter Country</option>
                                    <option [ngValue]="'CA'">
                                        Canada
                                    </option>
                                    <option [ngValue]="'KO'">
                                        Korea
                                    </option>
                                    <option [ngValue]="'MX'">
                                        Mexico
                                    </option>
                                    <option [ngValue]="'US'">
                                        United States
                                    </option>
                                </select>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                Address *
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <ion-input class="quickbooks-input" formControlName="address" placeholder="Enter city"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>

                <ion-row class="double-row">
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                {{(newCustomerForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <ion-input class="quickbooks-input" formControlName="address2" placeholder="Enter address"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                City *
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <ion-input class="quickbooks-input" formControlName="city" placeholder="Enter city"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>

                <ion-row class="double-row">
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                {{(newCustomerForm.value.country === 'CA') ? 'Province/Territory *' : 'State'}}{{(newCustomerForm.value.country === 'US' ? ' *' : '')}}
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <ion-input class="quickbooks-input" formControlName="state" placeholder="Enter state"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                Postal Code *
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <ion-input class="quickbooks-input" formControlName="postal" placeholder="Enter postal"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </div>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Supplier Code
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="customer-supplier-code" formControlName="supplierCode" placeholder="Enter supplier code"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Payment Term *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select name="customer-payment-term" class="quickbooks-input" #term formControlName="paymentTerm" [ngClass]="{'text-color-grey': !newCustomerForm.value.paymentTerm}">
                                <option [value]="null" disabled selected>Select payment term</option>
                                <ng-container *ngIf="loaded">
                                    <option [value]="'Due on receipt'">
                                        Due on Receipt
                                    </option>
                                    <option [value]="'Net 30 days, ADI'">
                                        Net 30 days, ADI
                                    </option>
                                    <option [value]="'Net 45 days, ADI'">
                                        Net 45 days, ADI
                                    </option>
                                    <option [value]="'Net 60 days, ADI'">
                                        Net 60 days, ADI
                                    </option>
                                </ng-container>
                            </select>
                        </ion-col>
                    </ion-row>    
                </ion-col>
            </ion-row>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Invoice Period *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select name="customer-invoice-period" class="quickbooks-input" #period formControlName="invoice_period" [ngClass]="{'text-color-grey': !newCustomerForm.value.invoice_period}">
                                <option [value]="null" disabled selected>Select invoice period</option>
                                <ng-container *ngIf="loaded">
                                    <option [value]="'weekly'">
                                        Weekly
                                    </option>
                                    <option [value]="'monthly'">
                                        Monthly
                                    </option>
                                </ng-container>
                            </select>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Primary Invoice Approver *
                        </ion-label>
                        <span (click)="addNewContact()" class="new-contact">Add New Contact</span>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select name="customer-primary-approver" class="quickbooks-input" #approver formControlName="primaryApprover" [ngClass]="{'text-color-grey': !newCustomerForm.value.primaryApprover}">
                                <option [value]="null" disabled selected>Select primary approver</option>
                                <ng-container *ngIf="loaded">
                                    <option [ngValue]="contact.id" *ngFor="let contact of contacts">
                                        {{contact.first_name}} {{contact.last_name}}
                                    </option>
                                </ng-container>
                            </select>
                        </ion-col>
                    </ion-row>   
                </ion-col>
            </ion-row>
            
            <ion-row class="label-row">
                <ion-label>
                    Notes
                </ion-label>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-textarea name="customer-notes" class="quickbooks-input" formControlName="notes" placeholder="Enter notes"></ion-textarea>
                </ion-col>
            </ion-row>
            <ion-row class="checkbox-row">
                <ion-col class="checkbox-col">
                    <ion-checkbox name="customer-combine-accounts" formControlName="seperate_line_items_by_account"></ion-checkbox>
                    <ion-label>Combine Accounts on a Single Invoice</ion-label>
                </ion-col>
                <ion-col class="checkbox-col">
                    <ion-checkbox name="customer-seperate-line-items" formControlName="seperate_line_items_by_liaison"></ion-checkbox>
                    <ion-label>Separate Line Items by Liaison</ion-label>
                </ion-col>
            </ion-row>
        </div>
        <ion-row class="button-row">
            <ion-col>
                <ion-button name="add-customer" [disabled]="!newCustomerForm.value.name || !newCustomerForm.value.address || !newCustomerForm.value.primaryApprover
                || !newCustomerForm.value.city || ((newCustomerForm.value.country === 'US') && !newCustomerForm.value.state) || !newCustomerForm.value.postal" type="submit">
                    <ng-container *ngIf="!creating">
                        Add Customer
                    </ng-container>   
                    <ng-container *ngIf="creating">
                        <ion-spinner name="dots"></ion-spinner>
                    </ng-container>
                </ion-button>
            </ion-col>
        </ion-row>
    </form>

</ion-grid>