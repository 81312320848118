import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IonInput, ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AdminApiService } from '../../services/admin-api-service';
import { IAccount, IAccountData } from '../../../../../app/shared/models/account.model';
import { ILocation } from '../../../../../app/shared/models/location.model';
import { ICustomer } from '../../../../../app/shared/models/customer.model';
import { ViewChild } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';

import { NewContactPopupComponent } from '../../pages/contacts/components/new-contact/new-contact.component';
import { NewCustomerPopupComponent } from '../../pages/customer/components/new-customer/new-customer.component';
import { NewLocationPopupComponent } from '../../pages/locations/components/new-location/new-location.component';
import { NewUserPopupComponent } from '../../pages/liaison-setup/components/new-liaison/new-liaison.component';
import { ILiaisonAccount } from '../../../../../app/shared/models/liaison-account.model';
import { User } from '../../../../../app/shared/models/user.model';
import { AccountContact, CustomerContact } from '../../../../../app/shared/models/contact.model';
import { Contact } from '../../../../../app/shared/models/contact.model';
import { SharedService } from '../../../../../app/shared/services/shared.service';
import { OvertimeRulesPopupComponent } from '../overtime-rules/overtime-rules.component';
import { IOvertimeRules } from '../overtime-rules/overtime-rules.component';
import { CurrencyPipe } from '@angular/common';
import { ChooseAccountPopupComponent } from '../choose-account/choose-account.component';
import { LiaisonAccountHistoryComponent } from '../account-history/account-history.component';

import { ChangeDetectorRef } from '@angular/core';
import { ILiaisonAccountHistory } from '../../../../shared/models/liaison-account-history.model';
import { OVERTIMEOPTIONS } from '../../../../../app/shared/models/liaison-account.model';
import { AssignLiaisonHistoryComponent } from '../../pages/liaison-details/components/assign-liaison-history/assign-liaison-history.component';
import { AssignLiaisonNewAccountComponent } from '../../pages/liaison-details/components/assign-liaison-new-account/assign-liaison-new-account.component';
import { IAccountHistory } from '../../../../../app/shared/models/account-history.model';
import { AlertController } from '@ionic/angular';

enum Tabs {
    CUSTOMER = 1,
    ACCOUNT = 2,
    LIAISON = 3,
    CONTACTS = 4,
    DONE = 5
};

interface IAccountIndescrepencies {
    account: IAccountHistory,
    liaisonHistory: ILiaisonAccountHistory[]
    accountTotal: number,
    liaisonTotal: number,
    start: Date,
    end: Date
}

interface IDescrepencyData {
    start: string, 
    end: string, 
    accountTotal: number, 
    liaisonTotal: number
}

@Component({
    selector: 'edit-account-walkthrough-popup-component',
    templateUrl: 'edit-account-walkthrough.component.html',
    styleUrls:['./edit-account-walkthrough.component.scss'],
    providers:[AdminApiService, CurrencyPipe]
})

export class EditAccountWalkthroughPopupComponent implements ViewWillEnter, OnInit {

    @ViewChild('rate') rateElement: IonInput;

    OVERTIMEOPTIONS = OVERTIMEOPTIONS;

    accountId: number = null;
    account: IAccountData = null;

    Tabs = Tabs;

    activeTab = Tabs.CUSTOMER;

    name: string = null;
    customer: number = null;
    location: number = null;

    prevLocation: number = null;

    selectedLocation: number = null;

    locations: ILocation[] = [];
    customers: ICustomer[] = [];

    contacts: Contact[] = [];
    assignedContacts: AccountContact[] = [];
    manualTriggerContact: number = 0;

    liaisons: User[] = [];
    allLiaison: User[] = [];
    assignedLiaisons: ILiaisonAccount[] = [];
    originalLiaisons: number[] = [];
    manualTrigger: number = 0;

    trigger: number = 0;
    refreshCurrencyInput: boolean = false;

    history: IAccountHistory[] = [];
    originalHistory: IAccountHistory[] = [];

    selectedData: IAccountHistory = null;
    selectedDataCopy: IAccountData = null;

    loaded: boolean = false;

    program: string = null;
    productDesc: string = null;

    totalBaseHours: number = 0;

    accountHistoryWithIndiscrepency: IAccountIndescrepencies[] = [];
    discrepancyList: IDescrepencyData[] = [];

    accountHistorySaveInProgress: boolean = false;

    showInactiveLiaisonAccount: boolean = false;

    autoSelectUser: number = null;

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        public sharedService: SharedService,
        private cdr: ChangeDetectorRef,
        private currencyPipe: CurrencyPipe,
        private alertController: AlertController
    ) {

    }

    async ngOnInit(): Promise<void> {

        if (!this.accountId) {
            await this.chooseAccount();
        }

        if ((this.locations.length <= 0) && (this.customers.length <= 0)) {
            const promises: any[] = [];

            promises.push(this.getCustomers());
            promises.push(this.getLocations());
            promises.push(this.getLiaisons());
            promises.push(this.getHistory());
            promises.push(this.getAccount());

            await Promise.all(promises);

            this.getHistoryUnmatchedByLiaisons();

            if (this.autoSelectUser) {
                const liaisonAccount = this.getLiaisonAccounts().find(l => l.user_id === this.autoSelectUser);

                if (liaisonAccount) {
                    this.editLiaisonList(liaisonAccount);
                }
            }

            this.loaded = true;
        }
    }

    omitSemiColon(e: any): void {
        if (e.key === ';') {
            e.preventDefault();
        }
    }

    async getCustomers(): Promise<void> {
        this.customers = (await this.adminApiService.getCustomers())?.filter(c => c.is_active);
    }
    
    async getLocations(): Promise<void> {
        this.locations = (await this.adminApiService.getLocations())?.filter(l => l.active);
    }

    async getLiaisons(): Promise<void> {
        this.allLiaison = (await this.adminApiService.getUsers('liaison'));
        this.liaisons = this.sharedService.sortFirstNameLastName<User>(this.allLiaison?.filter(l => l.active));
    }

    async getContacts(account: IAccountData): Promise<void> {
        this.contacts = this.sharedService.sortFirstNameLastName<Contact>((await this.adminApiService.getCustomerContacts(account.customers.id)).map(c => c.contacts));
    }

    async getHistory(): Promise<void> {
        this.history = await this.adminApiService.getAllAccountHistory(this.accountId);

        this.history.forEach((b: IAccountHistory) => {
            b.start_date = this.sharedService.getDefaultDate(b.start_date as any) as any;
            b.end_date = b.end_date ? this.sharedService.getDefaultDate(b.end_date as any) as any : null;
        });
        
        this.originalHistory = JSON.parse(JSON.stringify(this.history));

        this.refreshCurrencyInput = true;

        if (this.history.length > 0) {
            this.selectedData = JSON.parse(JSON.stringify(this.history[0]));
            this.selectedDataCopy = JSON.parse(JSON.stringify(this.history[0]));
        } else {
            this.addNew();
        }

        this.refreshCurrencyInput = false;
    }

    async getAccount(): Promise<void> {
        // Account is set to this.account after liaison data is processed so it doesnt appear jumpy on screen when updating
        const account = await this.adminApiService.getAccountsDataById(this.accountId);

        this.selectedLocation = account.locations?.id;

        await this.getLiaisonData(account);
        await this.getContacts(account);

        this.account = account;

        if (account?.account_contacts?.length <= 0) {
            this.addContactRow();
        }

        this.getTotalBaseHoursForLiaisons();
    }

    async getLiaisonData(account: IAccountData): Promise<void> {
        account.liaison_accounts.forEach(async (a: ILiaisonAccount) => {
            this.originalLiaisons.push(a.id);
            var history: ILiaisonAccountHistory[] = null;

            if (a?.id) {
                history = (await this.adminApiService.getAllHistoryByLiaisonAccount(a.id)).sort((x,y) => {
                    if (x.start_date > y.start_date) return 1;
                    if (x.start_date < y.start_date) return -1;
                    return 0;
                });
            }

            a.liaison_account_history = history ?? [];

            var startDate = new Date(new Date().setHours(0,0,0,0));

            a.base_hours_value = history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= startDate) &&
            ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= startDate) || (l.end_date === null)))?.hours ?? null;
            
            a.pay_rate_value = history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= startDate) &&
            ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= startDate) || (l.end_date === null)))?.rate ?? null;
        });
    }

    ionViewWillEnter(): void {
        
    }

    getTotalBaseHoursForLiaisons(): number {
        this.totalBaseHours = this.account.liaison_accounts.reduce((prev, current) => {
            return prev += (current.base_hours_value ?? 0);
        }, 0);

        return this.totalBaseHours;
    }

    getCurrentInvoiceRateAndHours(): any {
        var currentDate = new Date(new Date().setHours(0,0,0,0));
        var isUpcoming = false;

        // Sort current history by start date
        var currentHistory: IAccountHistory = this.history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= currentDate) &&
        ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= currentDate) || (l.end_date === null)));

        if (!currentHistory) {
            
            currentHistory = this.history.sort((x, y) => {
                if (x.start_date > y.start_date) return 1;
                if (x.start_date < y.start_date) return -1;
                return 0;
            })[0];
            
            if (currentHistory) {
                isUpcoming = true;
            }
        }

        return {invoiceRate: (currentHistory?.invoice_rate ?? 0), hours: (currentHistory?.contracted_hours ?? 0), isUpcoming};
    }

    getCurrentOrFutureHistory(): IAccountHistory[] {
        const currentDate = new Date(new Date().setHours(0,0,0,0));
        
        return this.history.filter(h => {
            const historyDate = new Date(new Date(h.end_date).setHours(24,0,0,0));
            return !h.end_date || (historyDate >= currentDate);
        });
    }

    isDisabledEntry(data?: IAccountHistory): boolean {
        if (!data) {
            if (!this.selectedData) {
                return false;
            }

            data = this.selectedData;
        }

        if (!data.end_date) {
            return false;
        }

        const currentDate = new Date(new Date().setHours(0,0,0,0));

        return (new Date(new Date(data.end_date).setHours(24,0,0,0)) < currentDate);
    }

    getHistoryUnmatchedByLiaisons(): IAccountIndescrepencies[] {
        const currentOrFutureHistory: IAccountHistory[] = this.getCurrentOrFutureHistory();
        const historyWithIndiscrepency: IAccountIndescrepencies[] = [];
        var lastHistoryEntry: IAccountIndescrepencies = null;

        this.accountHistoryWithIndiscrepency = [];
        this.discrepancyList = [];

        var continueLoop: boolean = true;
        var counter: number = 0;
 
        if (!currentOrFutureHistory || currentOrFutureHistory.length <= 0) {
            return [];
        }
 
        // Get earliest start date
        var startDateValue = currentOrFutureHistory.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())[0]?.start_date;

        if (!startDateValue) {
            continueLoop = false;
        }
        
        // Use start date if it is in the future
        // var startDate = this.sharedService.stringToDate(startDateValue as any);
        var startDate = this.sharedService.stringToDate(new Date().toISOString());

        // While loop adds one to start date everytime
        while (continueLoop) {
            //get contracted hours for that day
            const accountHistory = currentOrFutureHistory.find(c => {
                return ((this.sharedService.stringToStartDate(c.start_date as any) <= startDate) &&
                ((this.sharedService.stringToStartDate(c.end_date as any) > startDate) || (c.end_date === null)));
            });

            //if account ends on that day STOP
            if (!accountHistory) {
                continueLoop = false;
                continue;
            }

            const contractedHours = accountHistory?.contracted_hours;

            const liaisonAccountHistory: ILiaisonAccountHistory[] = [];

            if ((contractedHours === null) || (contractedHours === undefined)) {
                continueLoop = false;
                continue;
            }

            //grab each assigned hours for that day from all liaisons and make sure it is equal to contracted hours for that day
            const assignedTotalForDay: number = (this.account.liaison_accounts ?? []).reduce((prev, curr) => {
                const history = curr.liaison_account_history?.find(l => (this.sharedService.stringToStartDate(l.start_date as any) <= startDate) &&
                ((this.sharedService.stringToStartDate(l.end_date as any) > startDate) || (l.end_date === null)));
                
                if (history) {
                    liaisonAccountHistory.push(history);
                }

                const hours = history?.hours ?? 0;
                return prev += hours;
            }, 0);

            if (assignedTotalForDay !== contractedHours) {
                const historyObject: IAccountIndescrepencies = {
                    account: accountHistory,
                    liaisonHistory: liaisonAccountHistory, 
                    accountTotal: accountHistory?.contracted_hours,
                    liaisonTotal: liaisonAccountHistory?.reduce((prev, curr) => {
                        return prev += (curr?.hours ?? 0);
                    }, 0),
                    start: this.getFormatedDate(this.sharedService.stringToEndDate(startDate as any)) as any,
                    end: !accountHistory?.end_date ? null : this.getFormatedDate(this.sharedService.stringToEndDate(accountHistory?.end_date as any)) as any
                };
                
                if (!historyWithIndiscrepency.find(h => 
                    (JSON.stringify(h.account) === JSON.stringify(historyObject.account)) &&
                    (JSON.stringify(h.liaisonHistory) === JSON.stringify(historyObject.liaisonHistory)) &&
                    (h.accountTotal === historyObject.accountTotal) && 
                    (h.liaisonTotal === historyObject.liaisonTotal))) {
                    historyWithIndiscrepency.push(historyObject);

                    if (lastHistoryEntry) {
                        lastHistoryEntry.end = this.getFormatedDate(startDate) as any;
                    }
                    
                    lastHistoryEntry = historyObject;
                }
            } else {
                const historyObject: IAccountIndescrepencies = historyWithIndiscrepency.find(h => !h.end);

                if (historyObject) {
                    historyObject.end = this.getFormatedDate(startDate) as any;
                }
            }

            // Every liaison history has passed
            const liaisonHistoryPassed: boolean = (this.account.liaison_accounts ?? []).reduce((prev, curr) => {
                var allStartDatesPassed = true;
                
                curr.liaison_account_history.forEach(h => {
                    if (this.sharedService.stringToEndDate(h.start_date) > startDate) {
                        allStartDatesPassed = false;
                    }
                });


                return prev && allStartDatesPassed;
            }, true);

            //if all liaisons are at null and account has no end date STOP
            //if all liaisons are unassigned STOP
            if (!accountHistory.end_date && liaisonHistoryPassed && ((liaisonAccountHistory.length <= 0) ||
            (liaisonAccountHistory.every(l => (l.end_date === null))))) {
                continueLoop = false;
            }

            // Safe guard against infinite loop due to invalid data
            if (counter > 10000) {
                continueLoop = false;
            }

            // increase the day by one each loop
            startDate = new Date(new Date(JSON.parse(JSON.stringify(new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))))));
            counter++;
        }

        this.accountHistoryWithIndiscrepency = historyWithIndiscrepency;

        var lastEntry: IDescrepencyData = null;
        this.accountHistoryWithIndiscrepency.forEach(a => {
            const newDescrepencyData: IDescrepencyData = {
                accountTotal: a.accountTotal,
                liaisonTotal: a.liaisonTotal,
                start: a.start as any,
                end: a.end as any
            };

            if (lastEntry && (lastEntry.accountTotal === newDescrepencyData.accountTotal) && (lastEntry.liaisonTotal === newDescrepencyData.liaisonTotal)) {
                lastEntry.end = newDescrepencyData.end;
            } else {
                // Check if descrepancy is in the present or future
                if ((this.sharedService.stringToEndDate(newDescrepencyData.end) > new Date()) || !newDescrepencyData.end) {
                    this.discrepancyList.push(newDescrepencyData);
                    lastEntry = newDescrepencyData;
                }
            }
        });

        return historyWithIndiscrepency;
    }

    getFormatedDate(date: Date): string {
        return `${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}/${(date.getDate() < 10) ? '0' : ''}${date.getDate()}/${date.getFullYear()}`;
    }

    async chooseAccount(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ChooseAccountPopupComponent,
            componentProps: {

            },
            cssClass: "width-max-content grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            this.accountId = data;
        } else {
            this.closeModal(false);
        }
    }

    primaryContactUpdated(event, contact: AccountContact): void {
        if (event.detail.checked) {
            this.account.account_contacts.forEach(c => {
                if (c.contact_id !== contact.contact_id) {
                    c.primary_contact = false;
                } else {
                    c.primary_contact = true;
                }
            });
        }
    }

    async changeAccountHistory(la: ILiaisonAccount): Promise<void> {

        const user: User = this.liaisons.find(l => l.id === la.user_id);

        const modal = await this.modalCtrl.create({
            component: LiaisonAccountHistoryComponent,
            componentProps: {
                liaisonAccount: la,
                user: user,
                accountId: this.accountId
            },
            cssClass: "width-max-content grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(async () => {
                await this.getAccount();
                this.getHistoryUnmatchedByLiaisons();
            }, 0);
        }
    }

    async editLiaisonList(la: ILiaisonAccount): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AssignLiaisonHistoryComponent,
            componentProps: {
                liaisonAccountId: la.id,
                user: la.users,
                accountId: this.accountId
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(async () => {
                await this.getAccount();
                this.getHistoryUnmatchedByLiaisons();
            }, 0);
        }
    }

    async addNewLiaison(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AssignLiaisonNewAccountComponent,
            componentProps: {
                selectUser: true,
                initialAccountId: this.account?.id,
                excludeUserIdList: this.account.liaison_accounts.map(l => l.users.id)
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(async () => {
                await this.getAccount();
                this.getHistoryUnmatchedByLiaisons();
            }, 0);
        }
    }

    async createNewCustomer(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewCustomerPopupComponent,
            componentProps: {

            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getCustomers();

            this.customer = data?.id;
        }
    }

    async createNewLocation(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewLocationPopupComponent,
            componentProps: {

            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getLocations();

            this.location = data?.id;
        }
    }

    async createNewLiaison(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewUserPopupComponent,
            componentProps: {
                liaisons: this.allLiaison
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            this.getLiaisons();
        }
    }

    async createNewContact(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewContactPopupComponent,
            componentProps: {
                showCustomerOptions: true
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            const contact = new CustomerContact(null, data.contact.id, this.account.customers.id,
                data.values.primaryApprover, data.values.approver, data.values.accountsPayable, data.values.payable, data.values.notes,
                data.values.role, data.values.job_title, data.values.receive_invoice);

            await this.adminApiService.addCustomerContact(contact);

            await this.getContacts(this.account);

            contact.contact_id = data.contact.id;

            if (this.account.account_contacts.length > 0) {
                this.account.account_contacts[0].contact_id = data.contact.id;
            }
        }
    }

    async locationUpdated(): Promise<void> {
        if (!this.prevLocation || (this.prevLocation !== this.selectedLocation)) {
            const selectedLocation = this.locations.find(l => l.id === this.selectedLocation);
            this.account.qbo_name = {...selectedLocation}?.name;

            this.prevLocation = this.selectedLocation;
        }
    }

    refreshPipe(): void {
        this.trigger++;
    }

    updateManualTrigger(): void {
        this.manualTrigger++;
    }

    addContactRow() {
        const newAssigned: AccountContact = {
            contact_id: null,
            account_id: null,
            primary_contact: false
        }

        if (!this.account.account_contacts) {
            this.account.account_contacts = [];
        }

        this.account.account_contacts.push(newAssigned);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTriggerContact++;
    }

    removeContactRow(row: AccountContact): void {
        const index = this.account.account_contacts.indexOf(row);
        this.account.account_contacts.splice(index, 1);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTriggerContact++;
    }

    async addLiaisonRow() {
        const newAssigned: ILiaisonAccount = {
            account_id: this.accountId,
            base_hours: null,
            pay_rate: null,
            user_id: this.liaisons.filter(l => !this.account.liaison_accounts.map(r => r.user_id).includes(l.id))[0]?.id
        }

        const newLA = await this.adminApiService.addLiaisonAccount(newAssigned);

        this.account.liaison_accounts.push(newLA);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    removeLiaisonRow(row: ILiaisonAccount): void {
        const index = this.account.liaison_accounts.indexOf(row);
        this.account.liaison_accounts.splice(index, 1);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    async finish(): Promise<void> {
        const promises = [];
        const selectedCustomer = this.customers.find(c => c.id === this.customer);

        var hasUnfinishedData = false;

        const newAccount: IAccount = {
            id: this.account.id,
            name: this.account.name,
            customer_id: this.account.customers.id,
            location_id: this.selectedLocation,
            active: this.account.active,
            product_description: this.account.product_description,
            program: this.account.program,
            qbo_name: this.account.qbo_name,
            po_number: this.account.po_number
        };

        if (!this.accountHistoryValid()) {
            this.sharedService.presentToast('danger', 'Invalid data exist in account history.', 'Failed!', 'long');
            return;
        }

        var account: IAccount = null;
        try {
            account = await this.adminApiService.updateAccount(newAccount);
        } catch (error) {
            if (error.error === 'Quickbooks failed to sync') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by quickbooks not being synced with the QAS server.', 'Failed!', 'long');
            } else if (error.error === 'Quickbooks customer exists') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by a duplicate customer name.', 'Failed!', 'long');
            } else if (error.error === 'Stale Object Error') {
                this.sharedService.presentToast('danger', 'The Quickbooks customer was updated outside of QAS Track causing a stale object error. Please contact an administrator to resolve this issue.',
                'Stale Object Error!', 'long');
            } else if (error.error === 'Quickbooks failed to inactivate') {
                this.sharedService.presentToast('danger', 'The Quickbooks balance for this account is greater than 0 and cannot be inactivated until resolved.', 'Failed!', 'long');
            } else {
                this.sharedService.presentToast('danger', 'Account was not added successfully.', 'Failed!', 'long');
            }
        }

        if (!account) {
            return;
        }

        this.account.account_contacts.forEach(c => {
            if (c.contact_id) {
                if (c.id) {
                    promises.push(this.adminApiService.updateAccountContact(c));
                } else {
                    c.account_id = this.accountId;
                    promises.push(this.adminApiService.addAccountContact(c));
                }
            }
        });

        await Promise.all(promises);

        if (hasUnfinishedData) {
            //this.sharedService.presentToast('warning', 'One or more relationships were not included due to incomplete data.', 'Account was created!', 'med');
        } else {
            this.sharedService.presentToast('primary', '', 'Account was updated!', 'short');
        }
 
        this.closeModal(true);
    }

    isLastEntry(entry: IAccountHistory): boolean {
        return this.history.length <= 1;
    }

    getLiaisonAccounts(): ILiaisonAccount[] {
        return this.account.liaison_accounts.filter(la => this.sharedService.isActiveLiaisonAccount(la) || this.showInactiveLiaisonAccount);
    }

    async remove(data: IAccountHistory): Promise<void> {
        const alert = await this.alertController.create({
            header: `The selected account history for ${data.start_date} to ${data?.end_date ?? 'Future'} will be permanently deleted.`,
            message: `Would you like to continue?`,
            cssClass: 'custom-alert alert-button large-max-width-alert',
            buttons: [
                {
                    text: 'Confirm',
                    handler: async () => {
                        try {
                            // Delete entry
                            await this.adminApiService.deleteAccountHistory(data?.id);
                            // Update data on timeline
                            this.history = this.history.filter(h => h.id !== data?.id);
                            this.selectedData = this.history[0];
                            this.sharedService.presentToast('primary', '', 'Account history deleted successfully.', 'med');
                        } catch (error) {
                            console.log(error);
                            this.sharedService.presentToast('danger', 'Account history failed to delete successfully.', 'Unknown Error Occurred', 'long');
                        }
                    },
                    cssClass: 'alert-button'
                },
                {
                    text: 'Cancel',
                    handler: async () => {
                        
                    },
                    cssClass: 'alert-button no'
                }
            ],
        });
        
        await alert.present();
        await alert.onDidDismiss();
    }

    compare(a: IAccountHistory, b: IAccountHistory): boolean {
        return (a.end_date === b.end_date) && (a.contracted_hours === b.contracted_hours) && (a.start_date === b.start_date) && 
        (a.invoice_rate == b.invoice_rate) && (a.doubletime_holidays === b.doubletime_holidays) && (a.doubletime_sundays === b.doubletime_sundays) &&
        (a.overtime_holidays === b.overtime_holidays) && (a.overtime_hours === b.overtime_hours) && (a.overtime_option === b.overtime_option) &&
        (a.overtime_weekends === b.overtime_weekends) && (a.has_overtime === b.has_overtime);
    }

    accountHistoryValid(): boolean {
        return (this.history.length > 0) && !(this.history.map(h => h.invoice_rate).includes(null)) &&
        !(this.history.map(h => h.invoice_rate).includes(0)) && !(this.history.map(h => h.contracted_hours).includes(null));
    }

    setData(data: IAccountHistory): void {
        this.refreshCurrencyInput = true;
        this.selectedData = JSON.parse(JSON.stringify(data));
        this.selectedDataCopy = JSON.parse(JSON.stringify(data));
        
        setTimeout(() => {
            this.refreshCurrencyInput = false;
        }, 0);
    }

    isSelectedData(data: IAccountHistory): boolean {
        return this.selectedData.id === data.id;
    }

    currentEntryMatches(): boolean {
        return JSON.stringify(this.selectedData) === JSON.stringify(this.selectedDataCopy);
    }

    isFieldChanged(field: string): boolean {
        return !(JSON.stringify(this.selectedData[field]) === JSON.stringify(this.selectedDataCopy[field]));
    }

    dateChange(): void {
        if ((this.selectedData?.end_date as any) === '') {
            this.selectedData.end_date = null;
        }

        if ((this.selectedData?.start_date as any) === '') {
            this.selectedData.start_date = null;
        }
    }

    async saveAndNext(routeOnComplete: boolean = true): Promise<void> {
        this.accountHistorySaveInProgress = true;
        var invalid: boolean = false;
        
        if (this.selectedData.end_date && (new Date(this.selectedData.start_date) > new Date(this.selectedData.end_date))) {
            this.sharedService.presentToast('warning', 'End date must be greater than start date.', 'Invalid data', 'long');
            invalid = true;
            this.accountHistorySaveInProgress = false;
            return;
        }

        if ((this.sharedService.stringToEndDate(this.selectedData.start_date as any) < new Date()) && !invalid) {
            const alert = await this.alertController.create({
                header: `Account information will be changed.`,
                message: `Would you like to continue?`,
                cssClass: 'custom-alert alert-button large-max-width-alert',
                buttons: [
                    {
                        text: 'Confirm',
                        handler: async () => {

                        },
                        cssClass: 'alert-button'
                    },
                    {
                        text: 'Cancel',
                        handler: async () => {
                            invalid = true;
                        },
                        cssClass: 'alert-button no'
                    }
                ],
            });
            
            await alert.present();
            await alert.onDidDismiss();
            
            if (invalid) {
                this.accountHistorySaveInProgress = false;
                return;
            }
        }

        var showWarning: boolean = false;

        const promises: any[] = [];
        const newestHistory = this.getNewestHistory();

        var addNewEntry: boolean = false;
        var continueProcessing: boolean = true;

        if (this.originalHistory.length <= 0) {
            continueProcessing = false;
            addNewEntry = true;
        }

        this.history.forEach(h => {
            if ((this.selectedData.start_date === h.start_date) && (this.selectedData.end_date === h.end_date) && continueProcessing) {
                continueProcessing = false;
                showWarning = true;
                const id = h.id;
                var clone = JSON.parse(JSON.stringify(this.selectedData));
                clone.id = id;

                promises.push(this.adminApiService.updateAccountHistory(clone));
            }
        });

        if ((new Date(this.selectedData.start_date) >= new Date(newestHistory.start_date)) && continueProcessing) {
            continueProcessing = false;

            if (this.selectedData.start_date === newestHistory.start_date) {
                showWarning = true;
                var clone = JSON.parse(JSON.stringify(newestHistory));
                clone.end_date = this.selectedData.end_date;

                promises.push(this.adminApiService.updateAccountHistory(clone));
            } else {
                addNewEntry = true;

                // If there is no end date or the new start date overlaps existing end date update end date to new start date
                if (!newestHistory.end_date || (newestHistory.end_date && (new Date(this.selectedData.start_date) < new Date(newestHistory.end_date)))) {
                    showWarning = true;
                    var clone = JSON.parse(JSON.stringify(newestHistory));
                    clone.end_date = this.selectedData.start_date;

                    promises.push(this.adminApiService.updateAccountHistory(clone));
                } 
            }
        }

        var previousHistory = null;

        if (continueProcessing) {
            showWarning = true;
            addNewEntry = true;

            this.history.forEach((h, i) => {
                 if ((new Date(this.selectedData.start_date) > new Date(h.start_date)) && 
                 (new Date(this.selectedData.start_date) < new Date(h.end_date)) && 
                 (((new Date(this.selectedData.end_date) >= new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !this.selectedData.end_date)) {
                    //set end date to new start date
                    const clone = JSON.parse(JSON.stringify(h));
                    clone.end_date = this.selectedData.start_date;
    
                    promises.push(this.adminApiService.updateAccountHistory(clone));
                } else if ((new Date(this.selectedData.start_date) <= new Date(h.start_date)) && 
                (((new Date(this.selectedData.end_date) >= new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !this.selectedData.end_date)) {
                    // delete existing entry
                    promises.push(this.adminApiService.deleteAccountHistory(h.id));
                } else if ((new Date(this.selectedData.start_date) <= new Date(h.start_date)) && 
                (((new Date(this.selectedData.end_date) < new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !h.end_date) &&
                (((new Date(this.selectedData.end_date) > new Date(h.start_date)) && this.selectedData.end_date) || !this.selectedData.end_date)) {
                    // Set start date to new end date
                    const clone = JSON.parse(JSON.stringify(h));
                    clone.start_date = this.selectedData.end_date;

                    promises.push(this.adminApiService.updateAccountHistory(clone));
                } else if ((new Date(this.selectedData.start_date) > new Date(h.start_date)) && 
                ((new Date(this.selectedData.end_date) < new Date(h.end_date)) && this.selectedData.end_date && h.end_date)) {
                    // delete existing entry
                    promises.push(this.adminApiService.deleteAccountHistory(h.id));

                    // Split entry if new entry cuts existing entry in half
                    const newEntryOne = JSON.parse(JSON.stringify(h));
                    const newEntryTwo = JSON.parse(JSON.stringify(h));

                    newEntryOne.id = null;
                    newEntryTwo.id = null;
                    newEntryOne.end_date = this.selectedData.start_date;
                    newEntryTwo.start_date = this.selectedData.end_date;

                    promises.push(this.adminApiService.postAccountHistory(newEntryOne));
                    promises.push(this.adminApiService.postAccountHistory(newEntryTwo));
                }
    
                previousHistory = h;
            });
        }

        if (!invalid) {
            if (addNewEntry) {
                this.selectedData.id = null;
                promises.push(this.adminApiService.postAccountHistory(this.selectedData));
            }
    
            const data = await Promise.all(promises);
    
            await this.getHistory();

            this.sharedService.presentToast('primary', '', 'Account history updated!', 'med');

            if (routeOnComplete) {
                // this.next();
            }
        }

        this.accountHistorySaveInProgress = false;
    }

    getNewestHistory(): IAccountHistory {
        if (!this.history || this.history.length <= 0) {
            return null;
        }

        return this.history.sort((a, b) => {
            if (a.start_date > b.start_date) return -1;
            if (a.start_date < b.start_date) return 1;
            return 0;
        })[0];
    }

    async addNew(): Promise<void> {

        var startDate: Date = new Date(new Date().setHours(0,0,0,0));
        var prev: IAccountHistory = null;

        const newHistory: IAccountHistory = {
            id: null,
            account_id: prev?.account_id ?? this.accountId,
            invoice_rate: prev?.invoice_rate ?? null,
            contracted_hours: prev?.contracted_hours ?? null,
            has_overtime: prev?.has_overtime ?? false,
            overtime_holidays: prev?.overtime_holidays ?? false,
            overtime_hours: prev?.overtime_hours ?? 40,
            overtime_option: prev?.overtime_option ?? OVERTIMEOPTIONS.WEEKLY,
            overtime_weekends: prev?.overtime_weekends ?? false,
            doubletime_holidays: prev?.doubletime_holidays ?? false,
            doubletime_sundays: prev?.doubletime_sundays ?? false,
            signed_contract: prev?.signed_contract ?? false,
            start_date: this.sharedService.getDefaultDate(prev ? startDate.toISOString() : startDate.toISOString().split('T')[0]) as any,
            end_date: null
        };

        this.history.push(newHistory);

        setTimeout(() => {
            this.setData(newHistory);
        }, 0);
    }

    next(): void {
        this.activeTab++;

        if ((this.activeTab === Tabs.ACCOUNT) && (this.history.length === 0)) {
            this.addNew();
        }

        if (this.activeTab === Tabs.LIAISON) {
            this.getHistoryUnmatchedByLiaisons();
        }
    }

    back(): void {
        if (this.activeTab > 1) {
            this.activeTab--;

            if ((this.activeTab === Tabs.ACCOUNT) && (this.history.length === 0)) {
                this.addNew();
            }

            if (this.activeTab === Tabs.LIAISON) {
                this.getHistoryUnmatchedByLiaisons();
            }
        }
    }

    async updateActiveTab(tab: Tabs): Promise<void> {
        if ((this.activeTab === Tabs.ACCOUNT) && !this.currentEntryMatches()) {
            //await this.saveAndNext(false)
        }

        this.activeTab = tab;

        if ((tab === Tabs.ACCOUNT) && (this.history.length === 0)) {
            this.addNew();
        }

        if (tab === Tabs.LIAISON) {
            this.getHistoryUnmatchedByLiaisons();
        }
    }

    convertDate(date: string): Date {
        // Fixes date roll back on time zone
        return new Date(`${date.split('T')[0]}T12:00:00.000Z`);
    }

    closeModal(update: boolean = false){
        this.modalCtrl.dismiss(update);
    }
}