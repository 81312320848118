<ion-content>
    <ion-list>
        <ion-item class="popover-item">
            <ion-button class="reportBugButton" (click)="reportBug()">Report Bug</ion-button>
        </ion-item>
        <!-- <ion-item class="popover-item">
            <ion-button class="settingsButton" (click)="goToSettings()">Settings</ion-button>
        </ion-item> -->
        <ion-item class="popover-item" *ngIf="isAdmin()">
            <ion-button class="adminSettingsButton" (click)="goToCompanySettings()">Company Settings</ion-button>
        </ion-item>
        <ion-item class="popover-item" *ngIf="isLiaison()">
            <ion-button class="adminSettingsButton" (click)="goToPayentry()">Pay Entry</ion-button>
        </ion-item>
        <ion-item class="popover-item">
            <ion-button name="sign-out" class="signOutButton" (click)="signOut()">Sign Out</ion-button>
        </ion-item>
    </ion-list>
</ion-content>