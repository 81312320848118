import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LiaisonMessage } from "../../../shared/models/liaison-message.model";
import { LiaisonAccount } from "../../../shared/models/liaison-account.model";
import { LiaisonReport } from "../../../shared/models/liaison-report.model";
import { LiaisonStateService } from "./liaison-state-service";
import { LiaisonShift } from "../../../shared/models/liaison-shifts.model";
import { LiaisonIssue } from "../../../shared/models/liaison-issue.model";
import { environment } from "../../../../environments/environment";
import { catchError, first, firstValueFrom, map, of } from "rxjs";
import { LiaisonIssueUpdate } from "../../../shared/models/liaison-issue-updates.model";
import {
  Defect,
  LiaisonSort,
  PartNumber,
  Reject,
} from "../../../../app/shared/models/liaison-sort.model";
import { Contact, ExtendedContact, PrimaryContact } from "../../../shared/models/contact.model";
import { ISubmitReportFormat } from "../pages/liaison-review/models/submit-report.model";
import { User } from "../../../../app/shared/models/user.model";
import { IAdditionalTime } from "../../../../app/shared/models/additional-time.model";
import { ILiaisonContact } from "../../../../app/shared/models/liaison-contact.model";
import { ILiaisonAccountHistory } from "../../../shared/models/liaison-account-history.model";
import { IAccountData } from "../../../../app/shared/models/account.model";
import { ILocation } from "../../../../app/shared/models/location.model";
import { CacheInterceptor } from "../../../../app/shared/services/cache.service";
import { IAccepts, IOfflineSortBundle, IPartSort, ISortData, ISortReturnModel } from "../models/sorts.model";
import { IOfflineIdentifierData } from "../../../../app/shared/models/cache.model";
import { IDailyReportDisplayModel } from "../../../../app/shared/models/daily-report-display.model";
import { IWeeklyNote } from "../../../../app/shared/models/weekly-note.model";

// import { Defect } from 'src/app/shared/models/liaison-defect.model';

@Injectable()
export class LiaisonApiService {
  API_BASE_URL: string
  constructor(
    private httpClient: HttpClient,
    private liaisonStateService: LiaisonStateService,
    private cacheInterceptor: CacheInterceptor
  ) {
    this.API_BASE_URL = environment.API_URL;
  }

  //Liaison

  async getMessages(liaisonId: number) {
    return this.cacheInterceptor.processRequest<LiaisonMessage[]>(`${this.API_BASE_URL}/liaison/messages/${liaisonId}`, 'GET', true);
  }

  async getAccounts(liaisonId: number) {
    return this.cacheInterceptor.processRequest<LiaisonAccount[]>(`${this.API_BASE_URL}/liaison/${liaisonId}`, 'GET', true);
  }

  async resetPlayground() {
    return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/liaison/reset-playground`, 'POST', false);
  }

  async getLocations() {
    return this.cacheInterceptor.processRequest<ILocation[]>(`${this.API_BASE_URL}/locations`, 'GET', true);
  }

  async getLiaisonAccount(userId: number, accountId: number) {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/accounts/liaison-accounts/${accountId}/${userId}`, 'GET', false);
  }

  async getCurrentAccountHistory(accountId: number) {
    return this.cacheInterceptor.processRequest<ILiaisonAccountHistory>(`${this.API_BASE_URL}/accounts/account-history/${accountId}`, 'GET', false);
  }

  async login(token: any) {
    const results = await firstValueFrom(
      this.httpClient.post<any>(`${this.API_BASE_URL}/login`, {
        clientToken: token,
      })
    );
    return results;
  }

  async checkEmail(email: any) {
    const results = await firstValueFrom(
      this.httpClient.post<any>(`${this.API_BASE_URL}/login/check-email`, {
        email: email,
      })
    );
    return results;
  }

  async createUser(email: string, role: string) {
    const results = await firstValueFrom(
      this.httpClient.post<any>(`${this.API_BASE_URL}/login/create-user`, {
        email: email,
        role: role
      })
    );
    return results;
  }

  async setFirebaseUid(id: number, uid: string) {
    const results = await firstValueFrom(
      this.httpClient.post<any>(`${this.API_BASE_URL}/auth/set-firebase-uid`, {
        id, uid
      })
    );
    return results;
  }

  async checkIfUserHasPasswordLogin(email: string) {
    const results = await firstValueFrom(
      this.httpClient.get<boolean>(`${this.API_BASE_URL}/login/has-uid`)
    );
    return results;
  }

  async getUserIdByEmail(email: string) {
    return this.cacheInterceptor.processRequest<Number>(`${this.API_BASE_URL}/liaison/users/${email}`, 'GET', false);
  }

  //Accounts

  async getAccountById(accountId: number) {
    return this.cacheInterceptor.processRequest<IAccountData>(`${this.API_BASE_URL}/accounts/${accountId}`, 'GET', false);
  }

  async getPrimaryContact(accountId: number) {
    return this.cacheInterceptor.processRequest<ExtendedContact>(`${this.API_BASE_URL}/accounts/primary-contact/${accountId}`, 'GET', false);
  }

  async getTodaysReport(accountId: number, userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/current-day-report/${userId}/${accountId}`, 'GET', false);
  }

  async getAllReportsTodayByUserId(userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/all-current-day-reports/${userId}`, 'GET', false);
  }

  async getReportByDate(accountId: number, userId: number, date: string) {
    return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/report-by-date/${userId}/${accountId}/${date}`, 'GET', false);
  }

  async getReportsByReportIdArray(reportIdArray: { reportIds: number[] }) {
    const results = await firstValueFrom(
      this.httpClient.post<any[]>(
        `${this.API_BASE_URL}/reports/by-report-id-array/`,
        reportIdArray
      )
    );
    return results;
  }

  async deleteReports(reportIdArray: { reportIds: number[] }) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>( `${this.API_BASE_URL}/reports/delete-from-report-array`, 'POST', true, reportIdArray);
  }

  async getReportsInProgressByAccountAndUser(
    accountId: number,
    userId: number
  ) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/reports-by-account-and-user/${userId}/${accountId}`, 'GET', true);
  }

  async getReportHistory(accountId: number): Promise<LiaisonReport[]> {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/historical/${accountId}`, 'GET', true);
  }

  async getWeeklySubmittedReports(
    userId: number,
    accountId: number
  ): Promise<LiaisonReport[]> {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/weekly-submitted/${userId}/${accountId}`, 'GET', true);
  }

  async getWeeklyReportsForWeek(
    userId: number,
    accountId: number,
    date: string
  ): Promise<LiaisonReport[]> {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/weekly/${userId}/${accountId}/${date}`, 'GET', true);
  }

  async getAllReportsByAccountAndUser(accountId: number, userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/reports-by-account-and-user/${userId}/${accountId}`, 'GET', true);
  }

  async getAllHistoryAndCurrentReportsByAccountAndUser(
    accountId: number,
    userId: number
  ) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/all-reports/${userId}/${accountId}`, 'GET', true);
  }

  async getAllCurrentReportsByUser(userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonReport[]>(`${this.API_BASE_URL}/reports/all-unsubmitted-reports-by-user/${userId}`, 'GET', true);
  }

  async getDailyReport(reportId: number) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/${reportId}`, 'GET', false);
    } catch (error) {
      console.log(error);
    }
  }

  async updateDailyReport(reportId: number, newDailyReport: LiaisonReport) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/${reportId}`, 'PATCH', false, newDailyReport);
    } catch (error) {
      console.log(error);
    }
  }

  async postDailyReport(
    userId: number,
    accountId: number,
    report: LiaisonReport
  ) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/${userId}/${accountId}`, 'POST', false, report);
    } catch (error) {
      console.log(error);
    }
  }

  // Liaison Contacts

  async getAllLiaisonContacts() {
    return this.cacheInterceptor.processRequest<ILiaisonContact[]>(`${this.API_BASE_URL}/liaison-contacts`, 'GET', true);
  }

  async getAllLiaisonContactsById(id: number) {
    return this.cacheInterceptor.processRequest<ILiaisonContact[]>(`${this.API_BASE_URL}/liaison-contacts/${id}`, 'GET', true);
  }

  async getAllLiaisonContactsByAccount(id: number) {
    return this.cacheInterceptor.processRequest<ILiaisonContact[]>(`${this.API_BASE_URL}/liaison-contacts/account/${id}`, 'GET', true);
  }

  async postLiaisonContact(data: ILiaisonContact) {
    return this.cacheInterceptor.processRequest<ILiaisonContact>(`${this.API_BASE_URL}/liaison-contacts`, 'POST', false, data);
  }

  async updateLiaisonContact(data: ILiaisonContact) {
    return this.cacheInterceptor.processRequest<ILiaisonContact>(`${this.API_BASE_URL}/liaison-contacts`, 'PATCH', false, data);
  }

  async deleteLiaisonContact(id: number) {
    return this.cacheInterceptor.processRequest<ILiaisonContact>(`${this.API_BASE_URL}/liaison-contacts/${id}`, 'DELETE', false);
  }

  //Sorts

  async getDefects(accountId: number) {
    return this.cacheInterceptor.processRequest<Defect[]>(`${this.API_BASE_URL}/sorts/defects/${accountId}`, 'GET', true);
  }

  async postDefect(accountId: number, defect: Defect) {
    return this.cacheInterceptor.processRequest<Defect>(`${this.API_BASE_URL}/sorts/defects/${accountId}`, 'POST', false, defect);
  }

  async markDefectInactive(defectId: number) {
    return this.cacheInterceptor.processRequest<PartNumber>(`${this.API_BASE_URL}/sorts/set-defect-inactive/${defectId}`, 'PATCH', false, {defectId: defectId});
  }

  async getAccountAndReport(reportId: number) {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/account-and-report/${reportId}`, 'GET', false);
  }

  async getAccountByAccountId(id: number) {
    return this.cacheInterceptor.processRequest<IAccountData>(`${this.API_BASE_URL}/accounts/by-id/${id}`, 'GET', false);
  }

  async getPartNumbers(accountId: number) {
    return this.cacheInterceptor.processRequest<PartNumber[]>(`${this.API_BASE_URL}/sorts/part-numbers/${accountId}`, 'GET', true);
  }

  async createPartNumber(accountId: number, partNumber: PartNumber) {
    return this.cacheInterceptor.processRequest<PartNumber>(`${this.API_BASE_URL}/sorts/part-numbers/${accountId}`, 'POST', false, partNumber);
  }

  async getSort(sortId: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/${sortId}`, 'GET', false);
  }

  async getTodaysSort(reportId: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/by-report-id/${reportId}`, 'GET', false);
  }

  // Active sort endpoints start

  async getPartById(id: number) {
    return this.cacheInterceptor.processRequest<PartNumber>(`${this.API_BASE_URL}/sorts/part-number/${id}`, 'GET', false);
  }

  async getOrCreateSort(data: ISortData) {
    var partnumber = data.partNumber;

    if (!partnumber || (partnumber.length <= 0)) {
      partnumber = data.barcode;
    }

    return this.cacheInterceptor.processRequest<ISortReturnModel>(`${this.API_BASE_URL}/sorts/get-or-create-sort/${partnumber}`, 'POST', false, data, null, true);
  }

  async createNewSort(data: IOfflineSortBundle) {
    return this.cacheInterceptor.processRequest<IAccepts>(`${this.API_BASE_URL}/sorts/create-sort`, 'POST', false, data, null);
  }

  async createNewAccept(data: IAccepts) {
    return this.cacheInterceptor.processRequest<IAccepts>(`${this.API_BASE_URL}/sorts/create-new-accept`, 'POST', false, data);
  }

  async updateAccepts(id: number, accepts: number, parentIdentifier: IOfflineIdentifierData = null) {
    return this.cacheInterceptor.processRequest<IAccepts>(`${this.API_BASE_URL}/sorts/update-accept`, 'PATCH', false, {id, accepts}, parentIdentifier);
  }

  async createNewSortFromOldSortId(sort_id: number) {
    return this.cacheInterceptor.processRequest<IPartSort>(`${this.API_BASE_URL}/sorts/create-new-sort-by-old-sort-id`, 'POST', false, {sort_id});
  }

  async getSortReviewByReportIds(reportIds: number[]) {
    return this.cacheInterceptor.processRequest<LiaisonSort[]>(`${this.API_BASE_URL}/sorts/generate-sort-review-by-report-ids`, 'POST', false, {reportIds});
  }

  // Active sort endpoints end

  async getTodaysSortWithFilter(sortParams: {
    report_id: number;
    barcode?: string;
    bin_number?: string;
    part_number?: string;
  }) {
    return await firstValueFrom(
      this.httpClient.post(`${this.API_BASE_URL}/sorts/todays-sort`, sortParams)
    );
  }

  async createSort(reportId: number, sort: LiaisonSort) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/${reportId}`, 'POST', false, sort);
  }

  async updateSort(sortId: number, amountAccepted: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/`, 'PATCH', false, {sortId: sortId, amount_accepted: amountAccepted});
  }

  async deleteSort(sortId: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/${sortId}`, 'DELETE', false);
  }

  async deleteReject(rejectId: number) {
    return this.cacheInterceptor.processRequest<Reject>(`${this.API_BASE_URL}/sorts/rejects-and-reject-defects/${rejectId}`, 'DELETE', false);
  }

  async incrementSortAccepted(sortId: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/increment-accepted/${sortId}`, 'PATCH', false, {});
  }

  async decrementSortAccepted(sortId: number) {
    return this.cacheInterceptor.processRequest<LiaisonSort>(`${this.API_BASE_URL}/sorts/decrement-accepted/${sortId}`, 'PATCH', false, {});
  }

  async createRejectAndRejectDefects(sortId: number, reject: Reject, quantity?: number, parentIdentifier: IOfflineIdentifierData = null) {
    return this.cacheInterceptor.processRequest<Reject[]>(`${this.API_BASE_URL}/sorts/rejects-and-reject-defects/${sortId}/${quantity ?? 1}`, 'POST', false, reject, parentIdentifier);
  }

  async getRejectAndRejectDefects(sortId: number) {
    return this.cacheInterceptor.processRequest<Reject>(`${this.API_BASE_URL}/sorts/rejects-and-reject-defects/${sortId}`, 'GET', false);
  }

  //Shifts

  async postShift(userId: number, accountId: number, shift: LiaisonShift) {
    return this.cacheInterceptor.processRequest<LiaisonShift>(`${this.API_BASE_URL}/shifts/${userId}/${accountId}`, 'POST', false, shift);
  }

  async getShifts(userId: number, accountId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/${userId}/${accountId}`, 'GET', true);
  }

  async getShiftsByUser(userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/${userId}`, 'GET', true);
  }

  async patchShift(shiftId: number, hours: number) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonShift>(`${this.API_BASE_URL}/shifts/${shiftId}`, 'PATCH', false, { hours: hours });
    } catch (error) {
      console.log(error);
    }
  }

  async getWeeklyShifts(userId: number, accountId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/weekly-shifts/${userId}/${accountId}`, 'GET', true);
  }

  async getWeeklyCoverShifts(userId: number, accountId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/weekly-cover-shifts/${userId}/${accountId}`, 'GET', true);
  }

  async getAllWeeklyCoverShiftsByUser(userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/weekly-cover-shifts-by-user/all/${userId}`, 'GET', true);
  }

  async getShiftsByUserAndDate(userId: number, date: Date) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/range/${userId}/${date}`, 'GET', true);
  }

  async getWeeklySubmittedShifts(userId: number, accountId: number) {
    return this.cacheInterceptor.processRequest<LiaisonShift[]>(`${this.API_BASE_URL}/shifts/weekly-submitted/${userId}/${accountId}`, 'GET', true);
  }

  async checkForDescrepancies(accountId: number) {
    return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/shifts/time-entry/check-for-descrepencies/${accountId}`, 'GET', false);
  }

  //Issues

  async getIssues(reportId: number) {
    return this.cacheInterceptor.processRequest<LiaisonIssue[]>(`${this.API_BASE_URL}/issues/${reportId}`, 'GET', true);
  }

  async getIssuesByFromAllUserAccounts(userId: number) {
    return this.cacheInterceptor.processRequest<LiaisonIssue[]>(`${this.API_BASE_URL}/issues/by-user/${userId}`, 'GET', true);
  }

  async getNewIssuesInWeek(userId: number, date: string) {
    return this.cacheInterceptor.processRequest<LiaisonIssue[]>(`${this.API_BASE_URL}/issues/newIssues/${userId}/${date}`, 'GET', true);
  }

  async getAllIssues(reportId: number) {
    return this.cacheInterceptor.processRequest<LiaisonIssue[]>(`${this.API_BASE_URL}/issues/get-all/${reportId}`, 'GET', true);
  }

  async postIssue(accountId: number, reportId: number, issue: LiaisonIssue) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonIssue>(`${this.API_BASE_URL}/issues/${accountId}/${reportId}`, 'POST', false, issue);
    } catch (error) {
      console.log(error);
    }
  }

  async updateIssue(issueId: number, issue: LiaisonIssue) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonIssue>(`${this.API_BASE_URL}/issues/${issueId}`, 'PATCH', false, issue);
    } catch (error) {
      console.log(error);
    }
  }

  async getIssueUpdatesByReportId(reportId: number) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonIssueUpdate[]>(`${this.API_BASE_URL}/issues/updates/${reportId}`, 'GET', true);
    } catch (error) {
      console.log(error);
    }
  }

  async postIssueUpdate(issueId: number, issueUpdate: LiaisonIssueUpdate) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonIssueUpdate>(`${this.API_BASE_URL}/issues/updates/${issueId}`, 'POST', false, issueUpdate);
    } catch (error) {
      console.log(error);
    }
  }

  async updateIssueUpdate(updateId: number, issueUpdate: LiaisonIssueUpdate) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonIssueUpdate>(`${this.API_BASE_URL}/issues/updates/${updateId}`, 'PATCH', false, issueUpdate);
    } catch (error) {
      console.log(error);
    }
  }

  async setActiveFlagIssue(issueId: number, value: number, report_id: number) {
    // Sets the closed report id to the current id or null depending on if it is being opened or closed
    const report = (value === 0) ? report_id : null;

    try {
      return this.cacheInterceptor.processRequest<LiaisonIssueUpdate>(`${this.API_BASE_URL}/issues/set-active-flag/${issueId}`, 'PATCH', false, {active: value, closed_report_id: report});
    } catch (error) {
      console.log(error);
    }
  }

  async deleteMessage(messageId: number) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonMessage>(`${this.API_BASE_URL}/liaison/messages/${messageId}`, 'DELETE', false);
    } catch (error) {
      console.log(error);
    }
  }

  async markReportSubmitted(reportId: number) {
    try {
      return this.cacheInterceptor.processRequest<LiaisonReport>(`${this.API_BASE_URL}/reports/submit/${reportId}`, 'PATCH', false, {});
    } catch (error) {
      console.log(error);
    }
  }

  async markReportsSubmitted(reportIds: { reportIds: number[] }) {
    try {
      return this.cacheInterceptor.processRequest<{ reportIds: number[] }>(`${this.API_BASE_URL}/reports/batch-submit`, 'PATCH', false, reportIds);
    } catch (error) {
      console.log(error);
    }
  }

  async archiveUserAccount(id: number) {
    try {
      return this.cacheInterceptor.processRequest<User>(`${this.API_BASE_URL}/liaison/archive`, 'PATCH', false, {id: id});
    } catch (error) {
      console.log(error);
    }
  }

  async changeUserEmail(id: number, email: string) {
    try {
      return this.cacheInterceptor.processRequest<User>(`${this.API_BASE_URL}/liaison/change-email`, 'POST', false, {id, email});
    } catch (error) {
      console.log(error);
    }
  }

  async sendReportToCustomerViaEmail(report: IDailyReportDisplayModel, recipients: any[]) {
    try {
      return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/customer-report-email`, 'POST', false, {report, recipients});
    } catch (error) {
      console.log(error);
    }
  }

  async sendIssueToCustomerViaEmail(issueId: number, recipients: any[]) {
    try {
      return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/customer-report-email/issue`, 'POST', false, {issueId, recipients});
    } catch (error) {
      console.log(error);
    }
  }

  async sendCloseIssueToCustomerViaEmail(updateId: number, recipients: any[]) {
    try {
      return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/customer-report-email/close-issue`, 'POST', false, {updateId, recipients});
    } catch (error) {
      console.log(error);
    }
  }

  async sendIssueUpdateToCustomerViaEmail(updateId: number, recipients: any[], isOpenIssue: boolean = false) {
    try {
      return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/customer-report-email/issue-update`, 'POST', false, {updateId, recipients, isOpenIssue});
    } catch (error) {
      console.log(error);
    }
  }

  async sendAdditionalTimeToCustomerViaEmail(additionalTime: IAdditionalTime, email: string) {
    try {
      return this.cacheInterceptor.processRequest<boolean>(`${this.API_BASE_URL}/additional-time-email`, 'POST', false, {additionalTime, email});
    } catch (error) {
      console.log(error);
    }
  }

  async getUserDataById(userId: number): Promise<User>  {
    return this.cacheInterceptor.processRequest<User>(`${this.API_BASE_URL}/liaison/user-data/${userId}`, 'GET', true);
  }

  async getAdditionalHoursByAccountAndUserIdByCurrentWeek(accountId: number, userId: number): Promise<IAdditionalTime[]>  {
    return this.cacheInterceptor.processRequest<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user-account-by-week/${accountId}/${userId}`, 'GET', true);
  }

  async getAdditionalHoursByUserIdByCurrentWeek(userId: number): Promise<IAdditionalTime[]>  {
    return this.cacheInterceptor.processRequest<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user-by-week/${userId}`, 'GET', true);
  }

  async getAdditionalHoursByAccountAndUserId(accountId: number, userId: number): Promise<IAdditionalTime[]>  {
    return this.cacheInterceptor.processRequest<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user-account/${accountId}/${userId}`, 'GET', true);
  }

  async updateAdditionalHours(data: IAdditionalTime): Promise<IAdditionalTime>  {
    return this.cacheInterceptor.processRequest<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time`, 'PATCH', false, data);
  }

  async addAdditionalHours(data: IAdditionalTime): Promise<IAdditionalTime>  {
    return this.cacheInterceptor.processRequest<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time`, 'POST', false, data);
  }

  async sendBugReportEmail(userId: number, content: string): Promise<any>  {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/issue-report-email`, 'POST', false, {userId, content});
  }

  async generateReport(reportIds: string): Promise<any>  {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/reports/generate-report/${reportIds}`, 'GET', false);
  }

  async getWeeklyNoteByUserAccountDate(userId: number, accountId: number, date: Date): Promise<any>  {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/weekly-notes/get-weekly-note/${userId}/${accountId}/${date}`, 'GET', false);
  }

  async updateWeeklyNote(data: IWeeklyNote): Promise<any>  {
    return this.cacheInterceptor.processRequest<any>(`${this.API_BASE_URL}/weekly-notes/update-note`, 'POST', false, data);
  }
}
