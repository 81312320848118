import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { User } from "../../models/user.model";
import { PopoverController } from "@ionic/angular";
import { ProfilePopupComponent } from "./components/profile-popup/profile-popup.component";
import { LiaisonStateService } from "../../../../app/features/liaison/services/liaison-state-service";
import { ModalController } from "@ionic/angular";
import { ContactUsComponent } from "./components/contact-us-popup/contact-us-popup.component";
import { CacheInterceptor } from "../../services/cache.service";
import { SharedService } from "../../services/shared.service";
import { NavController } from '@ionic/angular';
import { LoginService } from "../../../../app/features/login/services/login.service";

@Component({
  selector: "app-header",
  templateUrl: "app-header.component.html",
  styleUrls: ["./styles/app-header.component.scss"],
  providers: [AuthService, LoginService],
})
export class AppHeaderComponent implements OnInit {
  
  user: User;
  userName: string = '';
  retryConnection: boolean = false;

  expanded: boolean = false;

  @Output() updateSize = new EventEmitter();

  constructor(
    private router: Router,
    private authService: AuthService,
    private popoverController: PopoverController,
    private liaisonStateService: LiaisonStateService,
    private modalController: ModalController,
    public cacheInterceptor: CacheInterceptor,
    private sharedService: SharedService,
    private navController: NavController,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    this.userName = `${this.user?.first_name} ${this.user?.last_name ?? ''}`;
  }

  navigateHome(): void {
    this.router.navigate([(this.liaisonStateService.getRole() === 'liaison') ? 'liaison/home' : 'admin/home']);
  }

  async openPopup(event: any): Promise<void> {
    const popover = await this.popoverController.create({
      component: ProfilePopupComponent,
      componentProps: {

      },
      event: event,
      cssClass: 'profile-popover',
      side: 'bottom',
      alignment: 'start'
    });

    await popover.present();

    const {data} = await popover.onDidDismiss();
  }

  async signOut(): Promise<void> {
    await this.loginService.signOut();
    this.navController.navigateRoot([""]);
  }

  async openContactUsModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ContactUsComponent,
      componentProps: {

      },
      cssClass: "full-width-full-height-modal",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
      breakpoints: [0, 1]
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();

    if (data) {
      
    }
  }

  isOffline(): boolean {
    return this.cacheInterceptor.isOffline && (this.user.role === 'liaison');
  }

  // Not in use
  isSyncing(): boolean {
    return false;
    // return this.cacheInterceptor.isSyncing && (this.user.role === 'liaison');
  }

  async checkConnection(): Promise<void> {
    this.retryConnection = true;
    const connected: boolean = await this.cacheInterceptor.checkConnection();

    setTimeout(() => {
      this.retryConnection = false;

      if (!connected) {
        this.sharedService.presentToast('danger', 'Application failed to connect to the server and is currently in offline mode.', 'Retry Failed!', 'med');
      } else {
        this.sharedService.presentToast('primary', 'Application is back in online mode.', 'Retry Successful!', 'med');
      }
    }, 1000);
  }
}
