import { Component, OnInit } from '@angular/core';
import { IonInput, ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AdminApiService } from '../../services/admin-api-service';
import { IAccount, IAccountData } from '../../../../../app/shared/models/account.model';

import { NewUserPopupComponent } from '../../pages/liaison-setup/components/new-liaison/new-liaison.component';
import { ILiaisonAccount } from '../../../../../app/shared/models/liaison-account.model';
import { User } from '../../../../../app/shared/models/user.model';
import { SharedService } from '../../../../../app/shared/services/shared.service';
import { CurrencyPipe } from '@angular/common';

import { ChangeDetectorRef } from '@angular/core';
import { ILiaisonAccountHistory } from '../../../../shared/models/liaison-account-history.model';
import { AssignLiaisonHistoryComponent } from '../../pages/liaison-details/components/assign-liaison-history/assign-liaison-history.component';
import { AssignLiaisonNewAccountComponent } from '../../pages/liaison-details/components/assign-liaison-new-account/assign-liaison-new-account.component';
import { IAccountHistory } from '../../../../../app/shared/models/account-history.model';


interface IAccountIndescrepencies {
    account: IAccountHistory,
    liaisonHistory: ILiaisonAccountHistory[]
    accountTotal: number,
    liaisonTotal: number,
    start: Date,
    end: Date
}

interface IDescrepencyData {
    start: string, 
    end: string, 
    accountTotal: number, 
    liaisonTotal: number
}

@Component({
    selector: 'liaison-assignment-popup-component',
    templateUrl: 'liaison-assignment.component.html',
    styleUrls:['./liaison-assignment.component.scss'],
    providers:[AdminApiService, CurrencyPipe]
})

export class LiaisonAssignmentPopupComponent implements ViewWillEnter, OnInit {

    accounts: IAccountData[] = [];
    allAccounts: IAccountData[] = [];
    liaisonAccounts: ILiaisonAccount[] = [];

    selectedAccount: number = null;

    liaisons: User[] = [];
    allLiaison: User[] = [];
    assignedLiaisons: ILiaisonAccount[] = [];
    originalLiaisons: number[] = [];
    manualTrigger: number = 0;

    totalBaseHours: number = null;

    loaded: boolean = false;

    initialLoad: boolean = true;
    accountLoading: boolean = false;

    showInactiveLiaisonAccount: boolean = false;

    accountHistoryWithIndiscrepency: IAccountIndescrepencies[] = [];
    discrepancyList: IDescrepencyData[] = [];

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        public sharedService: SharedService,
        private cdr: ChangeDetectorRef,
        private currencyPipe: CurrencyPipe
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        promises.push(this.getAccounts());
        promises.push(this.getLiaisons());

        await Promise.all(promises);

        await this.getAllAccounts();

        this.loaded = true;
    }

    selectAccount(account: IAccountData): void {
        this.selectedAccount = account.id;
        this.initialLoad = false
        this.accountUpdated();
    }

    getLiaisonAccounts(): ILiaisonAccount[] {
        const la = this.getAccountById(this.selectedAccount)?.liaison_accounts?.filter(la => this.sharedService.isActiveLiaisonAccount(la) || this.showInactiveLiaisonAccount) ?? [];
        this.liaisonAccounts = la;
        return la;
    }

    async getAccounts(): Promise<void> {
        const accounts = await this.adminApiService.getAccountsWithIndescrepancies();
        this.accounts = accounts.filter(a => this.sharedService.isActiveAccount(a)).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }

    async getAllAccounts(): Promise<void> {
        const allAccounts = await this.adminApiService.getAccountsData(true, true);

        this.allAccounts = allAccounts.filter(a => this.sharedService.isActiveAccount(a)).filter(a => !this.accounts.map(x => x.id).includes(a.id)).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }

    async getLiaisons(): Promise<void> {
        this.allLiaison = (await this.adminApiService.getUsers('liaison'));
        this.liaisons = this.sharedService.sortFirstNameLastName<User>(this.allLiaison?.filter(l => l.active));
    }

    getAccountById(id: number): IAccountData {
        const account = [...this.accounts, ...this.allAccounts].find(a => a.id === Number(id));

        if (!account) {
            return null;
        }

        return account;
    }


    async getLiaisonData(): Promise<void> {
        const account =  [...this.accounts, ...this.allAccounts].find(a => a.id === Number(this.selectedAccount));

        if (!account) {
            return;
        }

        for (var i = 0; i < account.liaison_accounts.length; i++) {
            this.originalLiaisons.push(account.liaison_accounts[i]?.id);
            account.liaison_accounts[i].liaison_account_history = [];
            var history: ILiaisonAccountHistory[] = null;

            if (account.liaison_accounts[i]?.id) {
                history = await this.adminApiService.getAllHistoryByLiaisonAccount(account.liaison_accounts[i].id);
            }

            account.liaison_accounts[i].liaison_account_history = history ?? [];

            var startDate = new Date(new Date().setHours(0,0,0,0));

            account.liaison_accounts[i].base_hours_value = history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= startDate) &&
            ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= startDate) || (l.end_date === null)))?.hours ?? null;
            
            account.liaison_accounts[i].pay_rate_value = history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= startDate) &&
            ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= startDate) || (l.end_date === null)))?.rate ?? null;
        }
    }

    ionViewWillEnter(): void {
        
    }

    getTotalBaseHoursForLiaisons(): number {
        const account =  [...this.accounts, ...this.allAccounts].find(a => a.id === Number(this.selectedAccount));

        if (!account) {
            return 0;
        }

        this.totalBaseHours = account.liaison_accounts.reduce((prev, current) => {
            return prev += (current.base_hours_value ?? 0);
        }, 0);

        return this.totalBaseHours;
    }

    accountUpdated(): void {
        this.liaisonAccounts = [];
        this.accountLoading = true;
        this.showInactiveLiaisonAccount = false;
        setTimeout(async () => {
            const account =  [...this.accounts, ...this.allAccounts].find(a => a.id === Number(this.selectedAccount));
            await this.getLiaisonData();
            this.getTotalBaseHoursForLiaisons();
            this.getHistoryUnmatchedByLiaisons(account);
            this.getLiaisonAccounts();
            this.accountLoading = false;
        }, 0);
    }

    showInactiveChange(): void {
        this.getLiaisonAccounts();
    }

    getCurrentInvoiceRateAndHours(): any {
        var currentDate = new Date(new Date().setHours(0,0,0,0));
        var isUpcoming = false;

        if (!this.selectedAccount) {
            return {invoiceRate: 0, hours: 0};
        }
        
        const account =  [...this.accounts, ...this.allAccounts].find(a => a.id === Number(this.selectedAccount));

        if (!account) {
            return {invoiceRate: 0, hours: 0};
        }

        var currentHistory: IAccountHistory = account.account_history.find(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= currentDate) &&
        ((new Date(new Date(l.end_date).setHours(24,0,0,0)) >= currentDate) || (l.end_date === null)));

        if (!currentHistory) {
            
            currentHistory = account.account_history.sort((x, y) => {
                if (x.start_date > y.start_date) return 1;
                if (x.start_date < y.start_date) return -1;
                return 0;
            })[0];
            
            if (currentHistory) {
                isUpcoming = true;
            }
        }

        return {invoiceRate: (currentHistory?.invoice_rate ?? 0), hours: (currentHistory?.contracted_hours ?? 0), isUpcoming};
    }

    getCurrentOrFutureHistory(history: any[]): IAccountHistory[] {
        const currentDate = new Date(new Date().setHours(0,0,0,0));
        
        return history.filter(h => {
            const historyDate = new Date(new Date(h.end_date).setHours(24,0,0,0));
            return !h.end_date || (historyDate >= currentDate);
        });
    }

    async getHistory(accountId: number): Promise<IAccountHistory[]> {
        const history: IAccountHistory[] = await this.adminApiService.getAllAccountHistory(accountId);

        history.forEach((b: IAccountHistory) => {
            b.start_date = this.sharedService.getDefaultDate(b.start_date as any) as any;
            b.end_date = b.end_date ? this.sharedService.getDefaultDate(b.end_date as any) as any : null;
        });
        
        return history;
    }

    async getHistoryUnmatchedByLiaisons(account: IAccountData): Promise<IAccountIndescrepencies[]> {
        const history = await this.getHistory(account.id);
        const currentOrFutureHistory: IAccountHistory[] = this.getCurrentOrFutureHistory(history);
        const historyWithIndiscrepency: IAccountIndescrepencies[] = [];
        var lastHistoryEntry: IAccountIndescrepencies = null;

        this.accountHistoryWithIndiscrepency = [];
        this.discrepancyList = [];

        var continueLoop: boolean = true;
        var counter: number = 0;
 
        if (!currentOrFutureHistory || currentOrFutureHistory.length <= 0) {
            return [];
        }

        // Get earliest start date
        var startDateValue = currentOrFutureHistory.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())[0]?.start_date;

        if (!startDateValue) {
            continueLoop = false;
        }

        // var startDate = this.sharedService.stringToDate(startDateValue as any);
        var startDate = this.sharedService.stringToDate(new Date().toISOString());

        // While loop adds one to start date everytime
        while (continueLoop) {
            //get contracted hours for that day
            const accountHistory = currentOrFutureHistory.find(c => {
                return ((this.sharedService.stringToStartDate(c.start_date as any) <= startDate) &&
                ((this.sharedService.stringToStartDate(c.end_date as any) > startDate) || (c.end_date === null)));
            });

            //if account ends on that day STOP
            if (!accountHistory) {
                continueLoop = false;
                continue;
            }

            const contractedHours = accountHistory?.contracted_hours;

            const liaisonAccountHistory: ILiaisonAccountHistory[] = [];

            if ((contractedHours === null) || (contractedHours === undefined)) {
                continueLoop = false;
                continue;
            }

            //grab each assigned hours for that day from all liaisons and make sure it is equal to contracted hours for that day
            const assignedTotalForDay: number = (account.liaison_accounts ?? []).reduce((prev, curr) => {
                const history = curr.liaison_account_history?.find(l => (this.sharedService.stringToStartDate(l.start_date as any) <= startDate) &&
                ((this.sharedService.stringToStartDate(l.end_date as any) > startDate) || (l.end_date === null)));
                
                if (history) {
                    liaisonAccountHistory.push(history);
                }

                const hours = history?.hours ?? 0;
                return prev += hours;
            }, 0);

            if (assignedTotalForDay !== contractedHours) {
                const historyObject: IAccountIndescrepencies = {
                    account: accountHistory,
                    liaisonHistory: liaisonAccountHistory, 
                    accountTotal: accountHistory?.contracted_hours,
                    liaisonTotal: liaisonAccountHistory?.reduce((prev, curr) => {
                        return prev += (curr?.hours ?? 0);
                    }, 0),
                    start: this.getFormatedDate(this.sharedService.stringToEndDate(startDate as any)) as any,
                    end: !accountHistory?.end_date ? null : this.getFormatedDate(this.sharedService.stringToEndDate(accountHistory?.end_date as any)) as any
                };
                
                if (!historyWithIndiscrepency.find(h => 
                    (JSON.stringify(h.account) === JSON.stringify(historyObject.account)) &&
                    (JSON.stringify(h.liaisonHistory) === JSON.stringify(historyObject.liaisonHistory)) &&
                    (h.accountTotal === historyObject.accountTotal) && 
                    (h.liaisonTotal === historyObject.liaisonTotal))) {
                    historyWithIndiscrepency.push(historyObject);

                    if (lastHistoryEntry) {
                        lastHistoryEntry.end = this.getFormatedDate(startDate) as any;
                    }
                    
                    lastHistoryEntry = historyObject;
                }
            } else {
                const historyObject: IAccountIndescrepencies = historyWithIndiscrepency.find(h => !h.end);

                if (historyObject) {
                    historyObject.end = this.getFormatedDate(startDate) as any;
                }
            }

            // Every liaison history has passed
            const liaisonHistoryPassed: boolean = (account.liaison_accounts ?? []).reduce((prev, curr) => {
                var allStartDatesPassed = true;
                
                curr.liaison_account_history.forEach(h => {
                    if (this.sharedService.stringToEndDate(h.start_date) > startDate) {
                        allStartDatesPassed = false;
                    }
                });


                return prev && allStartDatesPassed;
            }, true);

            //if all liaisons are at null and account has no end date STOP
            //if all liaisons are unassigned STOP
            if (!accountHistory.end_date && liaisonHistoryPassed && ((liaisonAccountHistory.length <= 0) || 
            (liaisonAccountHistory.every(l => (l.end_date === null))))) {
                continueLoop = false;
            }

            // Safe guard against infinite loop due to invalid data
            if (counter > 10000) {
                continueLoop = false;
            }

            // increase the day by one each loop
            startDate = new Date(new Date(JSON.parse(JSON.stringify(new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))))));
            counter++;
        }

        this.accountHistoryWithIndiscrepency = historyWithIndiscrepency;

        var lastEntry: IDescrepencyData = null;
        this.accountHistoryWithIndiscrepency.forEach(a => {
            const newDescrepencyData: IDescrepencyData = {
                accountTotal: a.accountTotal,
                liaisonTotal: a.liaisonTotal,
                start: a.start as any,
                end: a.end as any
            };

            if (lastEntry && (lastEntry.accountTotal === newDescrepencyData.accountTotal) && (lastEntry.liaisonTotal === newDescrepencyData.liaisonTotal)) {
                lastEntry.end = newDescrepencyData.end;
            } else {
                // Check if descrepancy is in the present or future
                if ((this.sharedService.stringToEndDate(newDescrepencyData.end) > new Date()) || !newDescrepencyData.end) {
                    this.discrepancyList.push(newDescrepencyData);
                    lastEntry = newDescrepencyData;
                }
            }
        });

        return historyWithIndiscrepency;
    }

    getFormatedDate(date: Date): string {
        return `${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}/${(date.getDate() < 10) ? '0' : ''}${date.getDate()}/${date.getFullYear()}`;
    }

    async editLiaisonList(la: ILiaisonAccount): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AssignLiaisonHistoryComponent,
            componentProps: {
                liaisonAccountId: la.id,
                user: la.users,
                accountId: this.selectedAccount,
                closeOnSave: true
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(async () => {
                const descrepancyAccount: boolean = !!this.accounts.find(a => a.id === Number(this.selectedAccount));

                // All accounts filter depends on updated account data 
                await this.getAccounts();
                await this.getAllAccounts();

                if (!this.accounts.find(a => a.id === Number(this.selectedAccount)) && descrepancyAccount) {
                    this.selectedAccount = this.allAccounts.find(a => a.id === Number(this.selectedAccount))?.id ?? null;
                    if (this.selectedAccount) {
                        this.accountUpdated();
                    }
                    this.sharedService.presentToast('primary', 'All descrepancies were resolved.', 'Account Updated!', 'med');
                } else {
                    this.accountUpdated();
                }
            }, 0);
        }
    }

    async addNewLiaison(): Promise<void> {
        const account = [...this.accounts, ...this.allAccounts].find(a => a.id === Number(this.selectedAccount));

        if (!account) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: AssignLiaisonNewAccountComponent,
            componentProps: {
                selectUser: true,
                initialAccountId: this.selectedAccount,
                excludeUserIdList: account.liaison_accounts.map(l => l.users.id)
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(async () => {
                const descrepancyAccount: boolean = !!this.accounts.find(a => a.id === Number(this.selectedAccount));

                // All accounts filter depends on updated account data 
                await this.getAccounts();
                await this.getAllAccounts();

                if (!this.accounts.find(a => a.id === Number(this.selectedAccount)) && descrepancyAccount) {
                    this.selectedAccount = this.allAccounts.find(a => a.id === Number(this.selectedAccount))?.id ?? null;
                    this.accountUpdated();
                    this.sharedService.presentToast('primary', 'All descrepancies were resolved.', 'Account Updated!', 'med');
                } else {
                    this.accountUpdated();
                }
            }, 0);
        }
    }

    async createNewLiaison(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewUserPopupComponent,
            componentProps: {
                liaisons: this.allLiaison
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            this.getLiaisons();
        }
    }


    updateManualTrigger(): void {
        this.manualTrigger++;
    }


    async addLiaisonRow(account: IAccountData) {
        const newAssigned: ILiaisonAccount = {
            account_id: account.id,
            base_hours: null,
            pay_rate: null,
            user_id: this.liaisons.filter(l => !account.liaison_accounts.map(r => r.user_id).includes(l.id))[0]?.id
        }

        const newLA = await this.adminApiService.addLiaisonAccount(newAssigned);

        account.liaison_accounts.push(newLA);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    removeLiaisonRow(row: ILiaisonAccount, account: IAccountData): void {
        const index = account.liaison_accounts.indexOf(row);
        account.liaison_accounts.splice(index, 1);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    async finish(): Promise<void> {
        const promises = [];

        await Promise.all(promises);
 
        this.closeModal(true);
    }

    compare(a: IAccountHistory, b: IAccountHistory): boolean {
        return (a.end_date === b.end_date) && (a.contracted_hours === b.contracted_hours) && (a.start_date === b.start_date) && 
        (a.invoice_rate == b.invoice_rate) && (a.doubletime_holidays === b.doubletime_holidays) && (a.doubletime_sundays === b.doubletime_sundays) &&
        (a.overtime_holidays === b.overtime_holidays) && (a.overtime_hours === b.overtime_hours) && (a.overtime_option === b.overtime_option) &&
        (a.overtime_weekends === b.overtime_weekends) && (a.has_overtime === b.has_overtime);
    }

    closeModal(update: boolean = false){
        this.modalCtrl.dismiss(update);
    }

    getAuthorizationStatus(): boolean {
        return (JSON.parse(this.sharedService.localStorageGet('user'))?.role === 'admin') ?? false;
    }
}