import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { Contact, PrimaryContact } from "../../models/contact.model";
import { ILiaisonAccount, LiaisonAccount } from "../../models/liaison-account.model";
import { LiaisonShift } from "../../models/liaison-shifts.model";
import { LiaisonApiService } from "../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../../app/features/liaison/services/liaison-state-service";
import { ActionSheetButton, ActionSheetController } from "@ionic/angular";
import { EmailComposer } from 'capacitor-email-composer';
import { AdminApiService } from "../../../../app/features/admin/services/admin-api-service";
import { ILiaisonAccountHistory } from "../../models/liaison-account-history.model";
import { IAccountData } from "../../models/account.model";


enum CONTACT_EVENT_TYPE {
  PHONE = 1,
  EMAIL = 2
};

@Component({
  selector: "account-info-component",
  templateUrl: "account-info.component.html",
  styleUrls: ["./account-info.component.scss"],
  providers: [LiaisonApiService, AdminApiService]
})

export class AccountInfoComponent implements OnInit {

  CONTACT_EVENT_TYPE = CONTACT_EVENT_TYPE;

  accountId: number = null;
  account: IAccountData = null;
  liaisonAccount: ILiaisonAccount = null;
  accountHistory: ILiaisonAccountHistory = null;

  shifts: LiaisonShift[] = [];
  totalHours: number = null;
  baseHours: number = null;
  primaryContact: PrimaryContact = null;
  payRate: string = null;
  additionalHours: number = 0;

  loaded: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private liaisonApiService: LiaisonApiService,
    private liaisonStateService: LiaisonStateService,
    private actionSheetController: ActionSheetController,
    private adminApiService: AdminApiService
  ) {

  }

  async ngOnInit(): Promise<void> {

    const promises: any[] = [];
    promises.push(this.getAccount());
    promises.push(this.getTimeForWeek());
    promises.push(this.getPrimaryContact());
    promises.push(this.getLiaisonAccount());
    promises.push(this.getAdditionalTime());

    await Promise.all(promises);

    this.loaded = true;  
  }

  async getAccount(): Promise<void> {
    this.account = await this.liaisonApiService.getAccountById(this.accountId);
  }

  async getLiaisonAccount(): Promise<void> {
    this.liaisonAccount = await this.liaisonApiService.getLiaisonAccount(this.liaisonStateService.getUserId(), this.accountId);

    this.baseHours = this.liaisonAccount?.base_hours ?? null;
    this.payRate = this.liaisonAccount?.pay_rate as any ?? null;
  }

  async getTimeForWeek(): Promise<void> {
      this.shifts = await this.liaisonApiService.getWeeklyShifts(this.liaisonStateService.getUserId(), this.accountId);
      this.totalHours = this.shifts.map((shift) => shift.hours_worked).reduce((partial, current) => partial + current, 0);
  }

  async getPrimaryContact() {
      const results = await this.liaisonApiService.getPrimaryContact(this.accountId);

      if (results?.contacts?.first_name) {
        this.primaryContact = {...results, contact_name: `${results?.contacts?.first_name} ${results?.contacts?.last_name ?? ''}`};
    } 
  }

  async getAdditionalTime(): Promise<void> {
    const additionalTimeApproval = await this.adminApiService.getAdditionalHoursByAccountAndUserIdByCurrentWeek(this.accountId, this.liaisonStateService.getUserId());

    this.additionalHours = additionalTimeApproval.filter(a => a.approved).reduce((data, current) => {
        return data += current.hours;
    }, 0);
}

  async contactPopup(event: CONTACT_EVENT_TYPE): Promise<void> {

    var buttons: any = [];

    if (event === CONTACT_EVENT_TYPE.PHONE) {
      buttons = [
        {
          text: `Call: ${this.primaryContact?.contacts?.phone ?? 'Not Available'}`,
          handler: () => { window.open(`tel:${this.primaryContact?.contacts?.phone}` )}
        },
        {
          text: `Text: ${this.primaryContact?.contacts?.phone ?? 'Not Available'}`,
          handler: () => { window.open(`sms:${this.primaryContact?.contacts?.phone}` )}
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ];
    } else if (event === CONTACT_EVENT_TYPE.EMAIL) {
      buttons = [
        {
          text: `Email: ${ this.primaryContact?.contacts?.email ?? 'Not Available' }`,
          handler: () => { this.email(this.primaryContact?.contacts?.email) }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ];
    } else {
      buttons = [
        {
        text: `Call: ${this.primaryContact?.contacts?.phone ?? 'Not Available'}`,
        handler: () => { window.open(`tel:${this.primaryContact?.contacts?.phone}`) }
        },
        {
          text: `Text: ${this.primaryContact?.contacts?.phone ?? 'Not Available'}`,
          handler: () => { window.open(`sms:${this.primaryContact?.contacts?.phone}`) }
        },
        {
          text: `Email: ${this.primaryContact?.contacts?.email ?? 'Not Available'}`,
          handler: () => { this.email(this.primaryContact?.contacts?.email) }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ];
    }

    if (this.primaryContact) {
      const actionSheet = this.actionSheetController.create({
        header: `Contact ${this.primaryContact.contact_name}}`,
        buttons: buttons
      });

      (await actionSheet).present();
    }
  }

  email(emailParam: string): void {
    try {
      if (emailParam) {
        const email = {
          to: [emailParam],
          cc: [],
          bcc: [],
          attachments: [],
          subject: '',
          body: '',
          isHtml: true
        };
  
        EmailComposer.open(email)
      } else {
        console.log('Email error');
      }

    } catch {
      console.log('Email error');
    }
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }
}
