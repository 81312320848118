<div class="container">
    <ion-row class="close-header">
        <ion-col class="logo">
            <img class="qas-logo" src="../.././../../../assets/logos/QAS Logo-main.svg"/>
        </ion-col>
        <ion-col size="auto" class="close">
            <ion-icon (click)="close()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>

    <ion-row class="label-row">
        <span>Report Bug</span>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-textarea [(ngModel)]="content" placeholder="Enter information about the issue you experienced or feel free to leave feedback for future improvements"></ion-textarea>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-button [disabled]="!content || (content.length <= 0)" (click)="sendData()">Send</ion-button>
        </ion-col>
    </ion-row>

</div>