import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PredefinedColors } from '@ionic/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Platform } from '@ionic/angular';

import moment from 'moment';
import { User } from '../models/user.model';
import { ILiaisonAccount } from '../models/liaison-account.model';
import { IAccountData } from '../models/account.model';

type DurationType = 'short' | 'med' | 'long' | 'extralong';

/**
 * Commonly used utilities & wrapper actions used across all pages & features
 */
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private toastr: ToastController,
    private location: Location,
    private platform: Platform
  ) { }

  //#region Toastr
  /**
   *
   * @param color
   * @param message
   * @param header
   * @param duration
   */
  async presentToast(color: PredefinedColors, message: string, header: string, duration: DurationType) {
    const toast = await this.toastr.create({
      color,
      message,
      header,
      duration: this.parseDuration(duration),
      keyboardClose: true,
      position: 'top'
    });

    toast.present();
  }


  clearToast() {
    return this.toastr.dismiss();
  }

  private parseDuration(duration: DurationType): number {
    switch (duration) {
      case 'extralong': return 8000;
      case 'long': return 5000;
      case 'med': return 3000;
      case 'short': return 1500;
      default: return 3000; //default;
    }
  }
  //#endregion

  //#region Routing by Location
  navigateBack() {
    this.location.back();
  }
  //#endregion

  //#region LocalStorage

  /**
   * Store values in localStorage
   *
   * @param key identifier
   * @param value value to cache
   */
  localStorageSet(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Retrieve a value from Local Storage
   *
   * @param key identifier
   * @returns value from cache
   */
  localStorageGet(key: string): string {
    return localStorage.getItem(key);
  }

  /**
   * Remove a value from Local Storage
   *
   * @param key identifier
   * @returns value from cache
   */
  localStorageRemove(key: string): void {
    return localStorage.removeItem(key);
  }

  localStorageClear(): void {
    localStorage.clear();
  }
  //#endregion

  //#region haptics

  async hapticsImpactMedium() {
    await Haptics.impact({ style: ImpactStyle.Medium });
  };

  async hapticsImpactLight() {
    await Haptics.impact({ style: ImpactStyle.Light });
  };

  async hapticsVibrate() {
    await Haptics.vibrate();
  };

  async hapticsSelectionStart() {
    await Haptics.selectionStart();
  };
  
  async hapticsSelectionChanged() {
    await Haptics.selectionChanged();
  };
  
  async hapticsSelectionEnd() {
    await Haptics.selectionEnd();
  };

  //#endregion

  isNative(): boolean {
    return this.platform.is('capacitor');
  }

  sameDate(date1: Date, date2: Date): boolean {
    return (date1.getFullYear() === date2.getFullYear()) && (date1.getDate() === date2.getDate()) && (date1.getMonth() === date2.getMonth());
  }

  sortFirstNameLastName<T,>(items: any[]): T[] {

    if (!items) {
      return [];
    }
    return items.sort((a, b) => {
      if (a.first_name > b.first_name) return 1;

      if (a.first_name < b.first_name) return -1;

      if (a.first_name === b.first_name) {
        if (a.last_name > b.last_name) return 1;

        if (a.last_name < b.last_name) return -1;
      }

      return 0;
    });
  }

  stringToDateCurrentTime(data: string): Date {
    const momentTime = moment.utc(data);
    const utcConversion = new Date(momentTime.year(), momentTime.month(), momentTime.date(), momentTime.hours(), momentTime.minutes(), momentTime.seconds(), momentTime.milliseconds());

    return utcConversion;
  }

  // Uses moment to correct for time zone issues
  stringToDate(data: string): Date {
    const momentTime = moment.utc(data).startOf('day');
    const utcConversion = new Date(momentTime.year(), momentTime.month(), momentTime.date(), momentTime.hours(), momentTime.minutes(), momentTime.seconds(), momentTime.milliseconds());

    return utcConversion;
  }

  getStandardizedFormat(data: string): string {
    const date = this.stringToDate(data);

    return `${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}-${(date.getDate() < 10) ? '0' : ''}${date.getDate()}-${date.getFullYear()}`
}

  stringToStartDate(data: string): Date {
    const momentTime = moment.utc(data).startOf('day');
    const utcConversion = new Date(momentTime.year(), momentTime.month(), momentTime.date(), 0, 0, 0, 0);

    return utcConversion;
  }

  stringToEndDate(data: string): Date {
    const momentTime = moment.utc(data).startOf('day');
    const utcConversion = new Date(momentTime.year(), momentTime.month(), momentTime.date(), 23, 59, 59, 0);

    return utcConversion;
  }

  getDefaultDate(dateString: string = null): string {
    const utcConversion = this.stringToDate(dateString);

    return `${utcConversion.getFullYear()}-${(utcConversion.getMonth() + 1) < 10 ? '0' : ''}${(utcConversion.getMonth() + 1)}-${utcConversion.getDate() < 10 ? '0' : ''}${utcConversion.getDate()}`;
  }

  getUpperAndLowerBoundOfWeek(date: Date = new Date()): {upper: string, lower: string} {
    const dayOfWeek: number = date.getDay();
    const currentDay: Date = new Date(date.setHours(0,0,0,0));
    const getDateFormat: number = currentDay.getDate();

    var upperBound: Date | null = null;
    var lowerBound: Date | null = null;
  
    lowerBound = new Date(new Date(JSON.parse(JSON.stringify(currentDay))).setDate(getDateFormat - dayOfWeek + ((dayOfWeek === 0) ? -6 : 1)));
    upperBound = new Date(new Date(JSON.parse(JSON.stringify(currentDay))).setDate(getDateFormat + (6 - dayOfWeek + ((dayOfWeek === 0) ? -6 : 1))));
  
    var upper = `${upperBound.toISOString().split('T')[0]}T23:59:59.000Z`;
    var lower = `${lowerBound.toISOString().split('T')[0]}T00:00:00.000Z`;
  
    return {upper, lower};
  }

  dateInCurrentWeek(date: Date): boolean {
    const {upper, lower} = this.getUpperAndLowerBoundOfWeek();

    const upperDate = this.stringToEndDate(upper);
    const lowerDate = this.stringToStartDate(lower);

    return (
      (date <= upperDate) &&
      (date >= lowerDate)
  );
  }

  addDaysToDate(date: string): Date {
    const momentTime = moment.utc(date).startOf('day');
    momentTime.add(6, 'days');

    const utcConversion = new Date(momentTime.year(), momentTime.month(), momentTime.date(), momentTime.hours(), momentTime.minutes(), momentTime.seconds(), momentTime.milliseconds());

    return utcConversion;
  }

  getWeekOfDate(date: Date = new Date()): number {
    let onejan = this.stringToStartDate(new Date(date.getUTCFullYear(), 0, 1).toISOString());

    if (date.getTime() === onejan.getTime()) {
      return 1;
    } else {
      let week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay()) / 7);

      return week;
    }
  }

  getAuthorizationStatus(): boolean {
    return (JSON.parse(this.localStorageGet('user'))?.role === 'admin') ?? false;
  }

  isAdmin(): boolean {
    return (JSON.parse(this.localStorageGet('user'))?.role === 'admin') ?? false;
  }

  isCustomer(): boolean {
    return (JSON.parse(this.localStorageGet('user'))?.role === 'customer') ?? false;
  }

  isLiaison(): boolean {
    return (JSON.parse(this.localStorageGet('user'))?.role === 'liaison') ?? false;
  }

  isLiaisonManager(): boolean {
    return (JSON.parse(this.localStorageGet('user'))?.role === 'liaison manager') ?? false;
  }

  getUser(): User {
    return JSON.parse(this.localStorageGet('user'))
  }

  isActiveLiaisonAccount(liaisonAccount: ILiaisonAccount) {
    const currentDate = new Date();

    const validHistory = liaisonAccount?.liaison_account_history?.find(la => !la.end_date || this.stringToDateCurrentTime(la.end_date) > currentDate);

    return validHistory != null;
  }

  isActiveAccount(account: IAccountData) {
    const currentDate = new Date();

    const validHistory = account?.account_history?.find(a => !a.end_date || this.stringToDateCurrentTime(a.end_date as any) > currentDate);

    return validHistory != null;
  }
}
