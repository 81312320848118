import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { IAccountData } from "../../../../app/shared/models/account.model";
import { IAdditionalTime } from "../../../../app/shared/models/additional-time.model";
import { CustomerReadStatus, DailyReport } from "../../liaison/models/daily-report.model";
import { IWeeklySummaryObject } from "../pages/home/models/weekly-summary.model";
import { ITimeEntry } from "../../../../app/shared/models/liaison-shifts.model";
import { User } from "../../../../app/shared/models/user.model";
import { AccountContact, CustomerContact, IAccountCustomerContacts } from "../../../../app/shared/models/contact.model";
import { IPersonnel } from "../pages/account-page/account-page.page";
import { ILiaisonAccountCover } from "../../../../app/shared/models/liaison-account-cover.model";
import { LiaisonStateService } from "../../liaison/services/liaison-state-service";
import { IDailyReportDisplayModel } from "../../../../app/shared/models/daily-report-display.model";
import { ILiveSort } from "../pages/home/models/live-sort.model";


@Injectable()
export class CustomerApiService {
  API_BASE_URL: string;

  constructor(
    private httpClient: HttpClient,
    private liaisonStateService: LiaisonStateService

  ) {
    this.API_BASE_URL = environment.API_URL;
  }

  async getDemoUser() {
    return await firstValueFrom(this.httpClient.get<User>( `${this.API_BASE_URL}/customer/demo/get-user`));
  }

  async getCustomerAccountsByUserId(id: number) {
    return await firstValueFrom(this.httpClient.get<IAccountData[]>( `${this.API_BASE_URL}/customer/${id}`));
  }

  async getAdditionalTimeByUserId(id: number) {
    return await firstValueFrom(this.httpClient.get<IAdditionalTime[]>( `${this.API_BASE_URL}/customer/additional-time/${id}`));
  }

  async getWeeklySummaryByUserId(id: number) {
    return await firstValueFrom(this.httpClient.get<IWeeklySummaryObject>( `${this.API_BASE_URL}/customer/weekly-summary/${id}`));
  }

  async getDailyReportsByAccountId(id: number, pulledReports: number[], page?: number, startDate?: string, endDate?: string, search: string = '_') {
    return await firstValueFrom(this.httpClient.post<IDailyReportDisplayModel[]>( `${this.API_BASE_URL}/customer/reports`, 
    {id, page: page ?? -1, search: search ?? '_', startDate, endDate, pulledReports}));
  }

  async getTimeEntriesByAccountId(id: number, page: number = -1, startDate?: string, endDate?: string, invoiceNumber: string = '-1') {
    return await firstValueFrom(this.httpClient.get<ITimeEntry[]>( `${this.API_BASE_URL}/customer/invoices/${id}/${page ?? -1}/${invoiceNumber ?? '-1'}/${startDate}/${endDate}`));
  }

  async getUsers() {
    return await firstValueFrom(this.httpClient.get<User[]>( `${this.API_BASE_URL}/customer/users/all`));
  }

  async markReportRead(reportId: number) {
    return await firstValueFrom(this.httpClient.post<CustomerReadStatus>( `${this.API_BASE_URL}/customer/mark-report-as-read`, {report_id: reportId, user_id: this.liaisonStateService.getUserId()}));
  }

  async getContacts(id: number) {
    return await firstValueFrom(this.httpClient.get<IAccountCustomerContacts>( `${this.API_BASE_URL}/customer/contacts/${id}`));
  }

  async getLiaisonAccounts(id: number) {
    return await firstValueFrom(this.httpClient.get<User[]>( `${this.API_BASE_URL}/customer/get-account-liaisons/${id}`));
  }

  async getLiaisonManagers(id: number) {
    return await firstValueFrom(this.httpClient.get<User[]>( `${this.API_BASE_URL}/customer/managers/${id}`));
  }

  async getQASPersonnel() {
    return await firstValueFrom(this.httpClient.get<IPersonnel[]>( `${this.API_BASE_URL}/customer/qas/personnel`));
  }

  async getLiaisonCoverage(id: number) {
    return await firstValueFrom(this.httpClient.get<ILiaisonAccountCover[]>( `${this.API_BASE_URL}/customer/liaison-coverage/${id}`));
  }

  async getLiveSortData() {
    return await firstValueFrom(this.httpClient.post<ILiveSort[]>( `${this.API_BASE_URL}/customer/get-live-sort-data`, {user_id: this.liaisonStateService.getUserId()}));
  }


}