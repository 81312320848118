<ion-grid name="add-new-liaison-popup">
    <ion-row class="header-row">
        <ion-col>
            Add New {{(role === 'liaison') ? 'Liaison' : 'User'}}
        </ion-col>
        <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
    </ion-row>
    <form [formGroup]="newForm" (ngSubmit)="submitNewLiaison()">
        <div class="form-container">

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            First Name *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="liaison-firstname" class="full-width payentry-input" formControlName="firstName" placeholder="Enter first name"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Last Name *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="liaison-lastname" class="full-width payentry-input" formControlName="lastName" placeholder="Enter last name"></ion-input>
                        </ion-col>
                    </ion-row>     
                </ion-col>
            </ion-row>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Email *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="liaison-email" class="full-width" (ionChange)="checkEmailValidity()" formControlName="email" placeholder="Enter email"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col *ngIf="requirePersonalEmail" class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Personal/Payentry Email *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="liaison-personalemail" class="full-width payentry-input" (ionChange)="checkEmailValidity()" formControlName="payentry_email" placeholder="Enter personal email"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col *ngIf="!requirePersonalEmail" class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Phone
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item phone-item">
                        <ion-col>
                            <div class="country-code-dropdown" (click)="countryCodeDropdownOpen = !countryCodeDropdownOpen">
                                <span *ngIf="countryCode === 1">+1</span>
                                <span *ngIf="countryCode === 52">+52</span>
            
                                <div *ngIf="countryCodeDropdownOpen" class="drop-down">
                                    <div [ngClass]="{'selected': (countryCode === 1)}" class="item" (click)="countryCode = 1">
                                        +1
                                    </div>
                                    <div [ngClass]="{'selected': (countryCode === 52)}" class="item top-line" (click)="countryCode = 52">
                                        +52
                                    </div>
                                </div>
                            </div>
                            <input name="liaison-phone" class="payentry-input" [mask]="(countryCode === 1) ? '000-000-0000' : '00 0000 0000'" type="tel" formControlName="phone_number" placeholder="Enter phone"/>
                        </ion-col>
                    </ion-row>            
                </ion-col>
            </ion-row>

            <ng-container *ngIf="requirePersonalEmail">
                <ion-row class="double-row">
                    <ion-col *ngIf="requirePersonalEmail" class="ion-no-padding">
                        <ion-row class="label-row full-width">
                            <ion-label>
                                Phone
                            </ion-label>
                        </ion-row>
                        <ion-row class="center-item phone-item">
                            <ion-col>
                                <div class="country-code-dropdown" (click)="countryCodeDropdownOpen = !countryCodeDropdownOpen">
                                    <span *ngIf="countryCode === 1">+1</span>
                                    <span *ngIf="countryCode === 52">+52</span>
                
                                    <div *ngIf="countryCodeDropdownOpen" class="drop-down">
                                        <div [ngClass]="{'selected': (countryCode === 1)}" class="item" (click)="countryCode = 1">
                                            +1
                                        </div>
                                        <div [ngClass]="{'selected': (countryCode === 52)}" class="item top-line" (click)="countryCode = 52">
                                            +52
                                        </div>
                                    </div>
                                </div>
                                <input name="liaison-phone" class="payentry-input" [mask]="(countryCode === 1) ? '000-000-0000' : '00 0000 0000'" type="tel" formControlName="phone_number" placeholder="Enter phone"/>
                            </ion-col>
                        </ion-row>            
                    </ion-col>
                    <ion-col *ngIf="requireBusiness" class="ion-no-padding">
                        <ion-row class="label-row full-width business-label">
                            <ion-label>
                                Business
                            </ion-label>
                            <span *ngIf="newForm.value.business_id" (click)="removeBusiness()">- Remove</span>
                        </ion-row>
                        <ion-row class="center-item">
                            <ion-col>
                                <select class="payentry-input" formControlName="business_id" [ngClass]="{'text-color-grey': !newForm.value.business_id}">
                                    <option [value]="null" disabled selected>Enter business</option>
                                    <ng-container *ngIf="businesses.length > 0">
                                        <option [ngValue]="business.id" *ngFor="let business of businesses">
                                            {{business.name}}
                                        </option>
                                    </ng-container>
                                </select>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ng-container>

            <ng-container *ngIf="!newForm.value.business_id">
                <ion-row class="label-row web-address" *ngIf="requireAddress">
                    <ion-col class="ion-no-padding">
                        <ion-label>
                            Country <span *ngIf="requireAddress">*</span>
                        </ion-label>
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        <ion-label>
                            Address <span *ngIf="requireAddress">*</span>
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="address-row web-address" *ngIf="requireAddress">
                    <ion-col>
                        <select name="liaison-country" formControlName="country" [ngClass]="{'text-color-grey': !newForm.value.country}">
                            <option [value]="null" disabled selected>Enter Country</option>
                            <option [ngValue]="'CA'">
                                Canada
                            </option>
                            <option [ngValue]="'KO'">
                                Korea
                            </option>
                            <option [ngValue]="'MX'">
                                Mexico
                            </option>
                            <option [ngValue]="'US'">
                                United States
                            </option>
                        </select>
                    </ion-col>
                    <ion-col>
                        <ion-input name="liaison-address" class="payentry-input" formControlName="address" placeholder="Enter Address"></ion-input>
                    </ion-col>
                </ion-row>
    
                <ion-row class="label-row web-address" *ngIf="requireAddress">
                    <ion-col class="ion-no-padding">
                        <ion-label>
                            {{(newForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                        </ion-label>
                    </ion-col>
                    <ion-col class="small-input-col ion-no-padding">
                        <ion-label>
                            City <span *ngIf="requireAddress">*</span>
                        </ion-label>
                    </ion-col>
                    <ion-col class="small-input-col ion-no-padding">
                        <ion-label>
                            {{(newForm.value.country === 'CA') ? 'Province/Territory' : 'State'}} <span *ngIf="requireAddress">{{(newForm.value.country === 'US') ? ' *' : ''}}</span>
                        </ion-label>
                    </ion-col>
                    <ion-col class="small-input-col ion-no-padding">
                        <ion-label>
                            Postal Code <span *ngIf="requireAddress">*</span>
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="address-row web-address" *ngIf="requireAddress">
                    <ion-col>
                        <ion-input name="liaison-address2" class="payentry-input" formControlName="address2" placeholder="Enter address"></ion-input>
                    </ion-col>
                    <ion-col class="small-input-col">
                        <ion-input name="liaison-city" class="payentry-input" formControlName="city" placeholder="Enter city"></ion-input>
                    </ion-col>
                    <ion-col class="small-input-col">
                        <ion-input name="liaison-state" class="payentry-input" formControlName="state" placeholder="Enter state"></ion-input>
                    </ion-col>
                    <ion-col class="small-input-col">
                        <ion-input name="liaison-postal" class="payentry-input" formControlName="postal" placeholder="Enter postal"></ion-input>
                    </ion-col>
                </ion-row>
            </ng-container>

            <!-- Switches address row to vertical when screen size is small -->

            <ng-container *ngIf="!newForm.value.business_id">
                <div class="mobile-address" *ngIf="requireAddress">
                    <ion-row class="double-row">
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    Country *
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <select formControlName="country" [ngClass]="{'text-color-grey': !newForm.value.country}">
                                        <option [value]="null" disabled selected>Enter Country</option>
                                        <option [ngValue]="'CA'">
                                            Canada
                                        </option>
                                        <option [ngValue]="'KO'">
                                            Korea
                                        </option>
                                        <option [ngValue]="'MX'">
                                            Mexico
                                        </option>
                                        <option [ngValue]="'US'">
                                            United States
                                        </option>
                                    </select>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    Address *
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <ion-input class="payentry-input" formControlName="address" placeholder="Enter address"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
    
                    <ion-row class="double-row">
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    {{(newForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <ion-input class="payentry-input" formControlName="address2" placeholder="Enter address"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    City *
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <ion-input class="payentry-input" formControlName="city" placeholder="Enter city"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
    
                    <ion-row class="double-row">
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    {{(newForm.value.country === 'CA') ? 'Province/Territory' : 'State'}} <span *ngIf="requireAddress">{{(newForm.value.country === 'US') ? ' *' : ''}}</span>
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <ion-input class="payentry-input" formControlName="state" placeholder="Enter state"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            <ion-row class="label-row full-width">
                                <ion-label>
                                    Postal Code *
                                </ion-label>
                            </ion-row>
                            <ion-row class="center-item">
                                <ion-col>
                                    <ion-input class="payentry-input" formControlName="postal" placeholder="Enter postal"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </div>
            </ng-container>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width" *ngIf="requireLiaisonManager">
                        <ion-label>
                            Liaison Manager
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item" *ngIf="requireLiaisonManager">
                        <ion-col>
                            <select name="liaison-manager" formControlName="manager" [ngClass]="{'text-color-grey': !newForm.value.manager}">
                                <option [value]="null" disabled selected>Enter manager</option>
                                <ng-container *ngIf="managers.length > 0">
                                    <option [ngValue]="manager.id" *ngFor="let manager of managers">
                                        {{manager.first_name}} {{manager.last_name ?? ''}}
                                    </option>
                                </ng-container>
                            </select>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col>
                    <ion-row *ngIf="require1099" class="checkbox-row">
                        <ion-col>
                            <ion-checkbox formControlName="eligible_for_1099" labelPlacement="end"></ion-checkbox>
                            <ion-label>Eligible for 1099</ion-label>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <!-- <ion-row *ngIf="require1099" class="checkbox-row">
                <ion-col>
                    <ion-checkbox formControlName="eligible_for_1099" labelPlacement="end"></ion-checkbox>
                    <ion-label>Eligible for 1099</ion-label>
                </ion-col>
            </ion-row> -->
            
        </div>
        <ion-row class="button-row">
            <ion-col>
                <ion-button [disabled]="disabledCheck()"
                name="add-liaison"
                type="submit">Add {{(role === 'liaison') ? 'Liaison' : 'User'}}</ion-button>
            </ion-col>
        </ion-row>
    </form>

</ion-grid>