<ion-grid>
    <ion-row class="header-row">
        <ion-col>
          
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>

    <div class="scroll-area">
        <ng-container *ngIf="!loading">
            <div class="left-column">
                <ion-row class="new-row">
                    <div>
                        <span>History</span>
                    </div>
                </ion-row>
                <ion-row class="table-row" *ngFor="let data of history | orderByStartDate: trigger" (click)="setData(data)">       
                    <div [ngClass]="{'active-date': isSelectedData(data)}" class="selectable date">
                        <span class="date-span">{{data.start_date | date:'shortDate'}}</span>
                        <span class="center-dash"> - </span>
                        <span class="date-span">
                            {{data.end_date ? (data.end_date | date:'shortDate') : 'Future'}}</span>     
                    </div>
                </ion-row>
                <!-- *ngIf="!isLastEntry(selectedData)" -->
                <ion-row class="remove-row selectable" (click)="remove(selectedData)">
                    <div>
                        <span>- Remove</span>
                    </div>
                </ion-row>
            </div>
            <div class="right-column">
                <ion-row>
                    <ion-col>
                        <div class="label-text">
                            <span>Pay Rate</span>
                            <ng-container *ngIf="!refreshCurrencyInput">
                                <currency-input [width]="100" [(value)]="selectedData.rate" [placeholder]="''"></currency-input>
                            </ng-container>
                            <ng-container *ngIf="refreshCurrencyInput">
                                <currency-input [width]="100" [placeholder]="''"></currency-input>
                            </ng-container>
                        </div>
                    </ion-col>
                    <ion-col>
                        <div class="label-text">
                            <span>Base Hours</span>
                            <ion-input type="number" [(ngModel)]="selectedData.hours"></ion-input>
                        </div>
                    </ion-col>
                </ion-row>
        
                <ion-row>
                    <ion-col>
                        <div class="label-text">
                            <span>Start Date</span>
                            <ion-input (ionChange)="dateChange()" [min]="minStartDate" type="date" [(ngModel)]="selectedData.start_date"></ion-input>
                        </div>
                    </ion-col>
                    <ion-col>
                        <div class="label-text">
                            <span>End Date</span>
                            <div>
                                <ion-input (ionChange)="dateChange()" [min]="minStartDate" type="date" [(ngModel)]="selectedData.end_date"></ion-input>
                            </div>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="descrepancy-row" [ngStyle]="{'opacity': hasDescrepancy ? 1 : 0}">
                    <ion-col>
                        * Contracted hours discrepancy found in this time range. Current contracted hours is {{this.contractedHours}}{{
                        sharedService.getAuthorizationStatus() ?
                        (' and invoice rate is ' + (this.invoiceRate | currency:'USD'))
                        : ''
                        }}.
                    </ion-col>
                </ion-row>

                <ion-row class="save-button">
                    <ion-col>
                        <!-- <div class="unassign" (click)="unassign()">
                            <span>Unassign</span>
                        </div> -->
                        <div class="save" (click)="save()">
                            <span>Save</span>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="upcoming-cover-title">
                    <ion-col>
                        <span>Upcoming Cross Coverage</span>
                    </ion-col>
                    <ion-col size="auto">
                        <div (click)="addLiaisonCover()" class="add-cover">
                            <span>Add Cross Coverage</span>
                        </div>
                    </ion-col>
                </ion-row>

                <div class="cover-container">
                    <ng-container *ngIf="coverHistory.length > 0">
                        <ion-row (click)="editCover(cover)" class="cover-row" *ngFor="let cover of coverHistory">
                            <ion-row>
                                <ion-col>
                                    {{cover?.users?.first_name ?? 'Unknown'}} {{cover?.users?.last_name ?? ''}}
                                </ion-col>
                                <ion-col>
                                    {{cover.start_date | date:'shortDate'}} - {{cover.end_date ? (cover.end_date | date:'shortDate') : 'Future'}}
                                </ion-col>
                                <!-- <ion-col size="auto">
                                    <ion-icon name="create-outline"></ion-icon>
                                </ion-col> -->
                            </ion-row>
                        </ion-row>
                    </ng-container>
                    <ng-container *ngIf="coverHistory.length <= 0">
                        <ion-row class="no-covers">
                            <ion-col>
                                No upcoming coverage
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </div>
            </div>

        </ng-container>

    </div>
</ion-grid>