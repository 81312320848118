<ion-row class="top-level-row" [ngStyle]="{'padding': hasPadding ? ('0px ' + paddingAmount) : '0px'}">
    <ion-col class="ion-no-padding">
        <accordion *ngIf="!imageUIOnly" #accordion [ngClass]="{'condensed-ui': condensed}" [fontSize]="condensed ? 14 : 18" 
        [title]="'Attachments' + (showAttachmentCount ? (' (' + getAttachmentLength() + ')') : '')" [hidden]="startHidden">
            <ion-row class="photo-carousel" *ngIf="attachments.length > 0 || unsyncedAttachments.length > 0">
                <div class="attachment-container" *ngFor="let attachment of getAllAttachments()">
                    <div *ngIf="showDelete" (click)="removeAttachment(attachment)" class="x-button"><div>X</div></div>
                    <div class="container-div">
                        <div>  
                            <ion-img (click)="previewAttachment(attachment)" [src]="attachment?.images?.image_url"></ion-img>
                        </div>
                    </div>
                </div>
            </ion-row>
            <ion-row class="no-attachment-text" *ngIf="attachments.length === 0 && unsyncedAttachments.length === 0">
                <ion-col>
                    No Attachments
                </ion-col>
            </ion-row>
            <ion-row *ngIf="showButtons">
                <ion-col class="ion-no-padding upload-photo">
                    <ion-button [disabled]="isDisabled" (click)="presentActionSheet()">
                        Upload Photo
                    </ion-button>
                </ion-col>
            </ion-row>
        </accordion>

        <ng-container *ngIf="imageUIOnly">
            <ion-row class="photo-carousel" *ngIf="attachments.length > 0 || unsyncedAttachments.length > 0">
                <div class="attachment-container" *ngFor="let attachment of getAllAttachments()">
                    <div *ngIf="showDelete" (click)="removeAttachment(attachment)" class="x-button"><div>X</div></div>
                    <div class="container-div">
                        <div>  
                            <ion-img (click)="previewAttachment(attachment)" [src]="attachment?.images?.image_url"></ion-img>
                        </div>
                    </div>
                </div>
            </ion-row>
            <ion-row class="no-attachment-text" *ngIf="attachments.length === 0 && unsyncedAttachments.length === 0">
                <ion-col>
                    No Attachments
                </ion-col>
            </ion-row>
            <ion-row *ngIf="showButtons">
                <ion-col class="ion-no-padding upload-photo">
                    <ion-button [disabled]="isDisabled" (click)="presentActionSheet()">
                        Upload Photo
                    </ion-button>
                </ion-col>
            </ion-row>
        </ng-container>
    </ion-col>
</ion-row>