import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { IonContent, ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LiaisonIssue } from "../../models/liaison-issue.model";
import { LiaisonIssueUpdate } from "../../models/liaison-issue-updates.model";
import { LiaisonApiService } from "../../../features/liaison/services/liaison-api-service";
import { FormGroup, FormControl } from "@angular/forms";
import { Subscription, debounceTime } from "rxjs";
import { LiaisonStateService } from "../../../features/liaison/services/liaison-state-service";
import { AttachmentUploadComponent } from "../attachment-upload/attachment-upload.component";
import { Keyboard } from "@capacitor/keyboard";
import { CloseOpenIssueComponent } from "../close-open-issue-popup/close-open-issue-popup.component";
import { ContactListComponent } from "../../../../app/features/liaison/pages/liaison-review/components/contact-list/contact-list.component";
import { SendToCustomerComponent } from "../send-to-customer-popup/send-to-customer-popup.component";
import { SharedService } from "../../services/shared.service";
import { CacheInterceptor } from "../../services/cache.service";
import { CreateIssueUpdateComponent } from "../create-issue-update/create-issue-update.component";


@Component({
  selector: "create-issue-component",
  templateUrl: "create-issue.component.html",
  styleUrls: ["./create-issue.component.scss"],
  providers: [LiaisonApiService],
})
export class CreateEditIssueComponent implements OnInit, ViewWillEnter {

  @ViewChild('attachmentUpload', { static: true }) attachmentComponent: any;
  @ViewChild('barcodeAccordion') barcodeAccordion: any;

  @Input() dailyReportId: number;
  @Input() activeIssue: LiaisonIssue;

  activeUpdate: LiaisonIssueUpdate;

  isWritingUpdate: boolean = false;

  updateContentFormGroup: FormGroup;

  obs: Subscription;

  creatingIssueInProgress: boolean = false;

  actionTaken: string = null;
  areaFound: string = null;

  groupedUpdates: {date: Date, updates: LiaisonIssueUpdate[]}[] = [];

  @ViewChild(IonContent) content: IonContent;

  constructor(
    private modalCtrl: ModalController,
    private liaisonApiService: LiaisonApiService,
    public liaisonStateService: LiaisonStateService,
    private sharedService: SharedService,
    private cacheInterceptor: CacheInterceptor
  ) {

  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.content.scrollToBottom();
    }, 500);
  }

  ngOnInit(): void {
    this.initializeUpdate();
  }

  ionViewWillEnter(): void {
    this.activeIssue.concern = (this.activeIssue.concern as any) === 1;

    this.groupUpdates();
  }

  groupUpdates(): void {
    if (this.activeIssue?.id) {
      this.groupedUpdates = this.getIssueUpdatesGroupedByDate();
    }
  }

  manualAttachmentComponentPopup(): void {
    if (this.attachmentComponent) {
      this.attachmentComponent.presentActionSheet();
    }
  }

  buildForm() {
    this.updateContentFormGroup = new FormGroup({
      content: new FormControl(this.activeUpdate?.content),
    });
    this.updateContentFormGroup.valueChanges
      .pipe(debounceTime(2000))
      .subscribe(() => {
        this.activeUpdate.content =
          this.updateContentFormGroup.controls.content.getRawValue();
        this.addOrUpdateUpdate();
      });
  }

  initializeUpdate() {
    // if (this.activeIssue && this.activeIssue.active && !this.isWritingUpdate) {
    //   this.serialNumbers = (this.activeIssue?.issue_serial_numbers ?? []).map(i => i.serial_number);
    // }
    
    this.buildForm();
  }

  async addUpdate(): Promise<void> {
    const popupModal = await this.modalCtrl.create({
      component: CreateIssueUpdateComponent,
      componentProps: {
        activeIssue: this.activeIssue
      },
      cssClass: "width-90-percent-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await popupModal.present();
    const { data } = await popupModal.onDidDismiss();

    if (data) {
      this.activeIssue.parts_found += (data.parts_found ?? 0); 
      const issueUpdate = await this.liaisonApiService.updateIssue(this.activeIssue.id, this.activeIssue);
      const update = await this.liaisonApiService.postIssueUpdate(this.activeIssue.id, data);

      if (this.attachmentComponent) {
        await this.attachmentComponent.refreshAttachmentData();
      }

      this.activeIssue.issue_updates.push(update);
      this.groupUpdates();

      // Allow user to immediatley send update to customer
      this.sendUpdateToCustomerPopup(update?.id);

      this.sharedService.presentToast('primary', '', 'Issue update created!', 'med');
    }
  }

  async sendUpdateToCustomerPopup(updateId: number, isOpenIssue: boolean = false): Promise<void> {
    const popupModal = await this.modalCtrl.create({
      component: SendToCustomerComponent,
      componentProps: {
        isUpdate: true
      },
      cssClass: "width-90-percent-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await popupModal.present();
    const { data } = await popupModal.onDidDismiss();

    if (data) {
      await this.sendUpdateToCustomer(updateId, isOpenIssue);
    }
  }

  async sendUpdateToCustomer(updateId: number, isOpenIssue: boolean = false): Promise<void> {
    const liaisonAccount = await this.liaisonApiService.getLiaisonAccount(this.liaisonStateService.getUserId(), this.liaisonStateService.getActiveAccountId());

    const modal = await this.modalCtrl.create({
      component: ContactListComponent,
      componentProps: {
        accountId: this.liaisonStateService.getActiveAccountId(),
        liaisonAccount,
        syncUpdates: false
      },
      cssClass: "min-width-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data) {
      const email = await this.liaisonApiService.sendIssueUpdateToCustomerViaEmail(updateId, data, isOpenIssue);
      
      this.sharedService.presentToast('primary', 'Issue update was been sent.', 'Success!', 'short');
    }
  }

  getTraceabilityBarcodes(update: LiaisonIssueUpdate): string {
    var barcodeString: string = '';

    update.issue_serial_numbers.forEach((i, index) => {
      if (index === (update.issue_serial_numbers.length - 1)) {
        barcodeString += `${i.serial_number}`;
      } else {
        barcodeString += `${i.serial_number}, `;
      }
    });

    return barcodeString;
  }

  getIssueUpdatesGroupedByDate(): {date: Date, updates: LiaisonIssueUpdate[]}[] {
    const dateGrouping: {date: Date, updates: LiaisonIssueUpdate[]}[] = [];

    (this.activeIssue?.issue_updates || []).sort((a, b) => {
      if (new Date(a.time_created) < new Date(b.time_created)) return 1;
      if (new Date(a.time_created) > new Date(b.time_created)) return -1;
      return 0;
    }).forEach(a => {
      const date = this.sharedService.stringToDate(a.time_created);
      const activeGroup = dateGrouping.find(d => d.date.getTime() === date.getTime());

      if (activeGroup) {
        activeGroup.updates.push(a);
      } else {
        dateGrouping.push({
          date: date,
          updates: [a]
        })
      }

    });

    return dateGrouping;
  }

  async addOrUpdateIssue() {
    this.creatingIssueInProgress = true;

    if (!this.activeIssue.parts_found || !this.activeIssue.description) {
      this.creatingIssueInProgress = false;
      this.sharedService.presentToast('warning', '', 'Number of nonconforming parts and description of nonconforming parts are required.', 'med');
      return;
    }

    var issue = null;

    if (this.activeIssue.id !== null) {
      this.attachmentComponent.manualSync(this.activeIssue.id);
      // this.activeIssue.serial_numbers = this.serialNumbers.filter(s => !this.activeIssue.issue_serial_numbers.map(i => i.serial_number).includes(s));

      issue = await this.liaisonApiService.updateIssue(
        this.activeIssue.id,
        this.activeIssue
      );

      this.closeModal(this.activeIssue);
    } else {
      const report = this.liaisonStateService.getActiveDailyReport()?.id;
      // this.activeIssue.serial_numbers = this.serialNumbers;

      issue = await this.liaisonApiService.postIssue(
        this.liaisonStateService.getActiveAccountId(),
        report,
        this.activeIssue
      );

      if (issue?.id) {
        this.attachmentComponent.manualSync(issue.id);

        if (this.actionTaken) {
          const update = new LiaisonIssueUpdate();
          update.is_local = true;
          update.created_by = this.liaisonStateService.getUserId();
          update.daily_report_id = this.liaisonStateService.getActiveDailyReport().id;
          update.content = this.actionTaken;
          update.issue_id = issue.id;
          update.time_created = new Date().toString();
          update.is_action_taken = true;
          update.area_found = this.areaFound;
          update.parts_found = this.activeIssue.parts_found ?? 0;

          const newIssueUpdate = await this.liaisonApiService.postIssueUpdate(
            issue.id,
            update
          );
        }

      } else if (this.cacheInterceptor.isOffline && issue) {
        // Issue will be a unique queue identifier in offline mode
        this.attachmentComponent.manualSync(null, issue);
      }

      this.closeModal(this.activeIssue);

      await this.sendToCustomerPopup(issue);
    }

    this.creatingIssueInProgress = false;
  }
  
  async sendToCustomerPopup(issue: LiaisonIssue): Promise<void> {
    const popupModal = await this.modalCtrl.create({
      component: SendToCustomerComponent,
      componentProps: {
        
      },
      cssClass: "width-90-percent-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await popupModal.present();
    const { data } = await popupModal.onDidDismiss();
    console.log('SEND TO CUSTOMER CLOSE')
    if (data) {
      await this.sendToCustomer(issue);
    }
  }

  activeIssueChange(event: any) {
  
  }

  async sendToCustomer(issue: LiaisonIssue): Promise<void> {
    const liaisonAccount = await this.liaisonApiService.getLiaisonAccount(this.liaisonStateService.getUserId(), this.liaisonStateService.getActiveAccountId());

    const modal = await this.modalCtrl.create({
      component: ContactListComponent,
      componentProps: {
        accountId: this.liaisonStateService.getActiveAccountId(),
        liaisonAccount,
        syncUpdates: false
      },
      cssClass: "min-width-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data) {
      const email = await this.liaisonApiService.sendIssueToCustomerViaEmail(issue?.id, data);
      this.sharedService.presentToast('primary', 'Issue was been sent.', 'Success!', 'short');
    }
  }

  setActiveUpdateAndIsWriting(isWriting: boolean) {
    this.isWritingUpdate = isWriting;
  }

  async addOrUpdateUpdate() {
    if (this.activeUpdate.content) {
      if (this.activeUpdate.is_local) {
        const newIssueUpdate = await this.liaisonApiService.postIssueUpdate(
          this.activeIssue.id,
          this.activeUpdate
        );
        
        if (!this.cacheInterceptor.isOffline) {
          this.activeUpdate = newIssueUpdate;
          this.activeUpdate.is_local = false;
        }
      } else {
        await this.liaisonApiService.updateIssueUpdate(
          this.activeUpdate.id,
          this.activeUpdate
        );
      }
    }
  }

  async setActiveFlag(value: number) {

    const modal = await this.modalCtrl.create({
      component: CloseOpenIssueComponent,
      componentProps: {isClosingIssue: !value},
      cssClass: "width-92-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data) {
      // Will need to add a flag for closing update
      const newUpdate = this.generateUpdate(data, value);
      const issueUpdate = await this.liaisonApiService.postIssueUpdate(this.activeIssue.id, newUpdate);

      if (value) {
        const closingUpdate = this.activeIssue.issue_updates.find(iu => iu.is_closing_update);

        if (closingUpdate) {
          closingUpdate.is_closing_update = false;
          await this.liaisonApiService.updateIssueUpdate(closingUpdate.id, closingUpdate)
        }
      }

      const report = this.liaisonStateService.getActiveDailyReport()?.id;
      await this.liaisonApiService.setActiveFlagIssue(this.activeIssue.id, value, report);

      // Allow user to immediatley send update to customer
      await this.sendUpdateToCustomerPopup(issueUpdate?.id, (value === 1));

      this.sharedService.presentToast('primary', '', `Issue ${value ? 'Opened': 'Closed'} Successfully`, 'med');
      this.closeModal(null);
    }
  }

  generateUpdate(data: {reason: string, cleanPointRequired: boolean, cleanDate: string}, value: number): LiaisonIssueUpdate {
    const newUpdate: LiaisonIssueUpdate = new LiaisonIssueUpdate();
    newUpdate.content = data.reason;
    newUpdate.clean_point_required = data.cleanPointRequired;
    newUpdate.clean_point_date = data.cleanDate;
    newUpdate.created_by = this.liaisonStateService.getUserId();
    newUpdate.daily_report_id = this.liaisonStateService.getActiveDailyReport().id;
    newUpdate.is_local = false;
    newUpdate.is_closing_update = (value === 0);

    return newUpdate;
  }

  async closeModal(update: LiaisonIssue = null) {
    if (this.updateContentFormGroup?.controls?.content?.getRawValue() && this.activeUpdate) {
      this.activeUpdate.content =
        this.updateContentFormGroup?.controls?.content?.getRawValue();
      //await this.addOrUpdateUpdate();
    }
    this.modalCtrl.dismiss(update);
  }
}
