import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDailyReportDisplayModel } from '../../models/daily-report-display.model';
import { LiaisonIssueUpdate } from '../../models/liaison-issue-updates.model';
import { SharedService } from '../../services/shared.service';
import { IAccountData } from '../../models/account.model';
import { IAttachment } from '../../models/attachment.model';

@Component({
    selector: 'liaison-report-content-component',
    templateUrl: 'liaison-report-content.component.html',
    styleUrls:['./liaison-report-content.component.scss']
})

export class LiaisonReportContentComponent implements OnInit {
    
    @Input() report: IDailyReportDisplayModel = null;

    sortImages: IAttachment[] = [];

    constructor(
        private sharedService: SharedService
    ) {

    }

    ngOnInit(): void {
        this.processNewIssues();
        this.getSortAttachments();
    }

    getSortAttachments(): void {
        this.sortImages = this.report.sorts?.flatMap(s => s.rejects)?.flatMap(r => r.reject_images) ?? [];
        console.log(this.sortImages);
    }

    processNewIssues(): void {
        // Add the initial update information to the issue for display reasons
        this.report.newIssues.forEach(i => {
            if (i.grouped_updates.length > 0) {
                // Get oldest updates
                const oldestUpdateGroup = i.grouped_updates.sort((a, b) => {
                    if (new Date(a.date) < new Date(b.date)) return 1;
                    if (new Date(a.date) > new Date(b.date)) return -1;
                    return 0;
                })[0];

                const oldestUpdate = oldestUpdateGroup?.updates.sort((a, b) => {
                    if (new Date(a.time_created) < new Date(b.time_created)) return 1;
                    if (new Date(a.time_created) > new Date(b.time_created)) return -1;
                    return 0;
                })[0];

                // TO DO: Check sorting is correct and display values
                if (oldestUpdate) {
                    oldestUpdateGroup.updates = oldestUpdateGroup.updates.filter(o => o.id !== oldestUpdate.id);

                    i.grouped_updates = i.grouped_updates.filter(g => g.updates.length > 0);

                    i.actionTaken = oldestUpdate.content;
                    i.nonConformedParts = oldestUpdate.parts_found;
                    i.areaFound = oldestUpdate.area_found;
                }
            }
        });
    }

    getTraceabilityBarcodes(update: LiaisonIssueUpdate): string {
        var barcodeString: string = '';
    
        update.issue_serial_numbers.forEach((i, index) => {
            if (index === (update.issue_serial_numbers.length - 1)) {
                barcodeString += `${i.serial_number}`;
            } else {
                barcodeString += `${i.serial_number}, `;
            }
        });
    
        return barcodeString;
    }


}