import { Account } from "../../../app/features/liaison/models/accounts.model";
import { ICustomerContactAccountInvoice } from "./customer-contact-account-invoice.model";

export class Contact {
  id: number;
  first_name: string = "";
  last_name: string = "";
  phone?: string = "";
  phoneExtension?: string = "";
  email?: string = "";
  active: boolean = true;
  primary_contact?: boolean = false;
  contact_id?: number;
  deskPhone?: string = "";
  deskPhoneExtension?: string = "";
}

export class ExtendedContact {
  account_id: number;
  accounts: Account;
  contact_id: number;
  contacts: Contact;
  id: number
}

export class PrimaryContact {
  id: number;
  account_id?: number;
  contact_name: string = "";
  contact_id: number;
  contacts: Contact;
}

export class AccountContact {
  id?: number;
  contact_id: number;
  account_id: number;
  primary_contact: boolean = false;
  contacts?: Contact = null;

  constructor(contact_id: number, account_id: number, primary_contact: boolean) {
    this.id = null;
    this.contact_id = contact_id;
    this.account_id = account_id;
    this.primary_contact = primary_contact;
  }
}

export class CustomerContact {
  id?: number;
  contact_id: number;
  customer_id: number;
  primary_approver: boolean = false;
  approver: boolean = false;
  accounts_payable_contact: boolean = false;
  payable_contact: boolean = false;
  contacts?: Contact = null;
  notes?: string = null;
  role?: string = null;
  job_title: string = null;
  receive_invoice: boolean = false;
  customer_contact_account_invoice?: ICustomerContactAccountInvoice[];
  receive_invoice_all_accounts: boolean = false;

  constructor(id: number = null, contact_id: number, customer_id: number, primary_approver: boolean,
     approver: boolean, accounts_payable_contact: boolean, payable_contact: boolean, notes: string,
     role: string, job_title: string, receive_invoice: boolean, receive_invoice_all_accounts?: boolean) {
    this.id = id;
    this.contact_id = contact_id;
    this.customer_id = customer_id;
    this.primary_approver = primary_approver;
    this.approver = approver;
    this.accounts_payable_contact = accounts_payable_contact;
    this.payable_contact = payable_contact;
    this.notes = notes;
    this.role = role;
    this.job_title = job_title;
    this.receive_invoice = receive_invoice;
    this.receive_invoice_all_accounts = receive_invoice_all_accounts ?? false
  }
}

export interface IAccountCustomerContacts {
  customer: CustomerContact[],
  account: AccountContact[]
}
