import { Injectable } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.auth.getToken();
    const userId = this.auth.getUser()?.id ?? 0;

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${authToken}`).set('loggedInUserId', userId.toString())
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
