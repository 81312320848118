export const environment = {
    production: false,
    name: 'development',
    biometricServerName: 'QASTrackMobileApp',
    firebase: {
        apiKey: "AIzaSyC6o5IY3f_BdfQR-HcyemxHkWoApMAyiiM",
        authDomain: "qas-liaison-staging.firebaseapp.com",
        projectId: "qas-liaison-staging",
        storageBucket: "qas-liaison-staging.appspot.com",
        messagingSenderId: "497199582036",
        appId: "1:497199582036:web:b075e661f005d0b1a2d6c1",
        measurementId: "G-083DMV3LTR"
    },
    appShellConfig: {
      debug: false,
      networkDelay: 500,
    },
    
    // API_URL: "https://qasmobileexpressapi-staging-ee74.up.railway.app/api",
    // Local Url
    API_URL: "https://playground-playgrounddemo.up.railway.app/api",
};
  