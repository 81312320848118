import { IAccountData } from "./account.model";
import { LiaisonIssueUpdate } from "./liaison-issue-updates.model";

export class LiaisonIssue {
    id:number;
    description:string;
    date_created:string;
    concern:boolean = false;
    concern_written: string;
    active:boolean;
    issue_updates: LiaisonIssueUpdate[] = [];
    issue_images: any[] = [];
    initial_report_id?: number;
    closed_report_id?: number;
    creator_id?: number;
    account_id?: number;
    accounts?: IAccountData;
    serial_numbers?: any[];
    parts_found?: number;
    impacted_parts?: string;
    is_update_only?: boolean;

    constructor(id:number,description:string,concern:boolean,date_created:string,concern_written:string,updates?: LiaisonIssueUpdate[],initial_report_id?: number,closed_report_id?: number,creator_id?:number,parts_found?:number){
        this.id = id;
        this.description = description;
        this.concern = concern;
        this.date_created = date_created;
        this.concern_written = concern_written
        this.active = true;
        this.issue_updates = updates ? updates : [];
        this.initial_report_id = initial_report_id ? initial_report_id : null;
        this.closed_report_id = closed_report_id ? closed_report_id: null;
        this.creator_id = creator_id ? creator_id : null;
        this.parts_found = parts_found ?? null
    }
}