<div id="content-report-export" class="data-section">
    <ion-row class="section-header">
        <ion-col class="ion-no-padding">
            Liaison
        </ion-col>
    </ion-row>

    <ion-row class="liaison-data">
        <ion-col class="ion-no-padding">
            <ion-row>
                <ion-col class="ion-no-padding">
                    {{report?.liaison?.first_name ?? 'No Name'}} {{report?.liaison?.last_name ?? ''}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.email">
                <ion-col class="ion-no-padding">
                    <a [href]="'mailto: ' + report.liaison.email">{{report.liaison.email ?? 'No Email'}}</a>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.phone_number">
                <ion-col class="ion-no-padding">
                    <a [href]="'tel: ' + report.liaison.phone_number">{{report.liaison.country_code ? ('+' + report.liaison.country_code + '') : ''}}{{report.liaison.phone_number ?? 'No Phone'}}</a>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="report?.dailyActivity.length > 0">
        <ion-col class="ion-no-padding">
            Activity
        </ion-col>
    </ion-row>

    <ion-row class="activity-data" *ngFor="let activity of report?.dailyActivity">
        <ion-col class="ion-no-padding">
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Daily Activity:</span> <br><span>{{activity.activity}}</span>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Production Count:</span> {{activity.productionCount}}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Scrap Count:</span> {{activity.scrapCount}}
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="report.newIssues.length > 0">
        <ion-col class="ion-no-padding">
            New Issues
        </ion-col>
    </ion-row>

    <ion-row class="issue-row" *ngFor="let issue of report.newIssues">
        <ion-col class="ion-no-padding">
            <ion-row class="issue-text" *ngIf="report.id?.length > 1">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Date:</span> {{issue.date_created | date: 'fullDate'}}
                </ion-col>
            </ion-row>
            <ion-row class="issue-text">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Issue:</span> {{issue.description}}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Total Parts Found:</span> {{issue.parts_found ?? 0}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.impacted_parts && (issue.impacted_parts.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Impacted Parts:</span> {{issue.impacted_parts}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.concern && issue.concern_written && (issue.concern_written.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Concern Written:</span> {{issue.concern_written}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.actionTaken">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Action Taken:</span> {{issue.actionTaken}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.nonConformedParts">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Number of Nonconforming Parts:</span> {{issue.nonConformedParts}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.areaFound">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Area in Plant Where Found:</span> {{issue.areaFound}}
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_updates.length > 0 && issue.grouped_updates.length > 0">
                <ion-col class="ion-no-padding">
                    Updates:
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let updateGroup of issue.grouped_updates">
                <ion-col class="ion-no-padding">
                    <ion-row class="update-date">
                        <ion-col class="ion-no-padding">
                          {{updateGroup.date | date: 'fullDate'}}
                        </ion-col>
                      </ion-row>
                    <ion-row [ngClass]="{'closing-update': update.is_closing_update}" class="issue-update-row"  *ngFor="let update of updateGroup.updates">
                        <ion-col class="ion-no-padding">
                            <ion-row *ngIf="update.content">
                                <span class="activity-text">Action Taken:</span>&nbsp;{{update.content}}
                            </ion-row>
                            <ion-row *ngIf="update.parts_found">
                                <span class="activity-text">Number of Nonconforming Parts:</span>&nbsp;{{update.parts_found}}
                            </ion-row>
                            <ion-row *ngIf="update.area_found">
                                <span class="activity-text">Area in Plant Where Found:</span>&nbsp;{{update.area_found}}
                            </ion-row>
                            <ion-row *ngIf="update.issue_serial_numbers && (update.issue_serial_numbers.length > 0)">
                                <ion-col class="ion-no-padding">
                                    <span class="activity-text">Traceability Barcodes:</span>&nbsp;{{getTraceabilityBarcodes(update)}}
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="update.clean_point_required">
                                <span class="activity-text">Clean Point Date:</span>&nbsp;{{update.clean_point_date | date: 'shortDate'}}
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_images.length > 0">
                <ion-col class="ion-no-padding">
                    Attachments:
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.issue_images.length > 0">
                <app-attachment-upload
                    #attachmentUpload
                    [condensed]="true"
                    [id]="issue.id"
                    [isDisabled]="true"
                    [context]="'issue'"
                    [startHidden]="true"
                    [showButtons]="false"
                    [showAttachmentCount]="true"
                    [paddingAmount]="'0px'"
                    [imageUIOnly]="true"
                    [showDelete]="false"
                ></app-attachment-upload>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="report.updatedIssues.length > 0">
        <ion-col class="ion-no-padding">
            Updated Issues
        </ion-col>
    </ion-row>

    <ion-row class="issue-row" *ngFor="let issue of report.updatedIssues">
        <ion-col class="ion-no-padding">
            <ion-row class="issue-text">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Issue:</span> {{issue.description}}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Total Parts Found:</span> {{issue.parts_found ?? 0}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.impacted_parts && (issue.impacted_parts.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Impacted Parts:</span> {{issue.impacted_parts}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.concern && issue.concern_written && (issue.concern_written.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Concern Written:</span> {{issue.concern_written}}
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_updates.length > 0">
                <ion-col class="ion-no-padding">
                    Updates:
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let updateGroup of issue.grouped_updates">
                <ion-col class="ion-no-padding">
                    <ion-row class="update-date">
                        <ion-col class="ion-no-padding">
                          {{updateGroup.date | date: 'fullDate'}}
                        </ion-col>
                      </ion-row>
                    <ion-row [ngClass]="{'closing-update': update.is_closing_update}" class="issue-update-row"  *ngFor="let update of updateGroup.updates">
                        <ion-col class="ion-no-padding">
                            <ion-row *ngIf="update.content">
                                <span class="activity-text">Action Taken:</span>&nbsp;{{update.content}}
                            </ion-row>
                            <ion-row *ngIf="update.parts_found">
                                <span class="activity-text">Number of Nonconforming Parts:</span>&nbsp;{{update.parts_found}}
                            </ion-row>
                            <ion-row *ngIf="update.area_found">
                                <span class="activity-text">Area in Plant Where Found:</span>&nbsp;{{update.area_found}}
                            </ion-row>
                            <ion-row *ngIf="update.issue_serial_numbers && (update.issue_serial_numbers.length > 0)">
                                <ion-col class="ion-no-padding">
                                    <span class="activity-text">Traceability Barcodes:</span>&nbsp;{{getTraceabilityBarcodes(update)}}
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="update.clean_point_required">
                                <span class="activity-text">Clean Point Date:</span>&nbsp;{{update.clean_point_date | date: 'shortDate'}}
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_images.length > 0">
                <ion-col class="ion-no-padding">
                    Attachments:
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.issue_images.length > 0">
                <app-attachment-upload
                    #attachmentUpload
                    [condensed]="true"
                    [id]="issue.id"
                    [isDisabled]="true"
                    [context]="'issue'"
                    [startHidden]="true"
                    [showButtons]="false"
                    [showAttachmentCount]="true"
                    [paddingAmount]="'0px'"
                    [imageUIOnly]="true"
                    [showDelete]="false"
                ></app-attachment-upload>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="report.closedIssues.length > 0">
        <ion-col class="ion-no-padding">
            Closed Issues
        </ion-col>
    </ion-row>

    <ion-row class="issue-row" *ngFor="let issue of report.closedIssues">
        <ion-col class="ion-no-padding">
            <ion-row class="issue-text">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Issue:</span> {{issue.description}}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Total Parts Found:</span> {{issue.parts_found ?? 0}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.impacted_parts && (issue.impacted_parts.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Impacted Parts:</span> {{issue.impacted_parts}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.concern && issue.concern_written && (issue.concern_written.length > 0)">
                <ion-col class="ion-no-padding">
                    <span class="activity-text">Concern Written:</span> {{issue.concern_written}}
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_updates.length > 0">
                <ion-col class="ion-no-padding">
                    Updates:
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let updateGroup of issue.grouped_updates">
                <ion-col class="ion-no-padding">
                    <ion-row class="update-date">
                        <ion-col class="ion-no-padding">
                          {{updateGroup.date | date: 'fullDate'}}
                        </ion-col>
                      </ion-row>
                    <ion-row [ngClass]="{'closing-update': update.is_closing_update}" class="issue-update-row"  *ngFor="let update of updateGroup.updates">
                        <ion-col class="ion-no-padding">
                            <ion-row *ngIf="update.content">
                                <span class="activity-text">Action Taken:</span>&nbsp;{{update.content}}
                            </ion-row>
                            <ion-row *ngIf="update.parts_found">
                                <span class="activity-text">Number of Nonconforming Parts:</span>&nbsp;{{update.parts_found}}
                            </ion-row>
                            <ion-row *ngIf="update.area_found">
                                <span class="activity-text">Area in Plant Where Found:</span>&nbsp;{{update.area_found}}
                            </ion-row>
                            <ion-row *ngIf="update.issue_serial_numbers && (update.issue_serial_numbers.length > 0)">
                                <ion-col class="ion-no-padding">
                                    <span class="activity-text">Traceability Barcodes:</span>&nbsp;{{getTraceabilityBarcodes(update)}}
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="update.clean_point_required">
                                <span class="activity-text">Clean Point Date:</span>&nbsp;{{update.clean_point_date | date: 'shortDate'}}
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_images.length > 0">
                <ion-col class="ion-no-padding">
                    Attachments:
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.issue_images.length > 0">
                <app-attachment-upload
                    #attachmentUpload
                    [condensed]="true"
                    [id]="issue.id"
                    [isDisabled]="true"
                    [context]="'issue'"
                    [startHidden]="true"
                    [showButtons]="false"
                    [showAttachmentCount]="true"
                    [paddingAmount]="'0px'"
                    [imageUIOnly]="true"
                    [showDelete]="false"
                ></app-attachment-upload>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="report.sorts.length > 0">
        <ion-col class="ion-no-padding">
            Sorts
        </ion-col>
    </ion-row>

    <ng-container *ngIf="report.sorts && (report.sorts.length > 0)">
        <ion-row class="sub-row" *ngFor="let sort of report.sorts">
            <ion-col class="ion-no-padding">
                <ion-row class="sort-header">
                    <ion-col class="ion-no-padding">
                        Part #
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Qty
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Rejected
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Reworked
                    </ion-col>
                </ion-row>
                <ion-row class="table-data">
                    <ion-col class="ion-no-padding">
                        {{sort.part_numbers?.part_number ? sort.part_numbers?.part_number : sort.part_numbers?.barcode}}
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        {{sort.total}}
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        {{sort.rejectsQuantity}}
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        {{sort.reworksQuantity}}
                    </ion-col>
                </ion-row>

                <div class="defect-container">
                    <ion-row class="sort-header" *ngIf="sort.defects.length > 0">
                        <ion-col class="ion-no-padding">
                            Defect
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            Qty
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            Pass Rate
                        </ion-col>
                    </ion-row>

                    <ion-row class="table-data" *ngFor="let defect of sort.defects">
                        <ion-col class="ion-no-padding">
                            {{defect.name}}
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            {{defect.rejects}}
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            {{defect.rate | percent}}
                        </ion-col>
                    </ion-row>
                </div>
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="sortImages.length > 0">
        <ion-row class="section-header">
            <ion-col class="ion-no-padding">
                Sort Attachments
            </ion-col>
        </ion-row>
    
        <ion-row>
            <app-attachment-upload
                #attachmentUpload
                [condensed]="true"
                [attachments]="sortImages"
                [isDisabled]="true"
                [context]="'issue'"
                [startHidden]="true"
                [showButtons]="false"
                [showAttachmentCount]="true"
                [paddingAmount]="'0px'"
                [imageUIOnly]="true"
                [showDelete]="false"
            ></app-attachment-upload>
        </ion-row>
    </ng-container>

</div>